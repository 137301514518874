import React, {useEffect} from "react";
import "./Emotions.scss";
import {connect} from 'react-redux';
import {setDose} from '../../../store/doses/actions';
const Emotions = (props) => {
  const [active, setActive] = React.useState(false);
  const handleSelectMoods = async(mood) =>{
    let moods = props.dose.emotions;
    if(!active){
      moods.push({
        mood,
        comment: ""
      })
    }else{
      moods = moods.filter(m => m.mood !== mood);
    }
    props.setDose({...props.dose, emotions: moods})
    setActive(!active);
  }
  useEffect(() => {
    if(props.dose.emotions){
      let selectedMood = props.dose.emotions.filter(emtn => emtn.mood === props.item.name)
      if(selectedMood.length){
        setActive(true)
      }
    }
  }, [props.dose])
  return (
    <>
      <div
        className={`Emotions ${active ? "active" : ""}`}
        onClick={() => {
          handleSelectMoods(props.item.name)
        }}
      >
        <img src={props.item.img} />
        <h6>{props.item.name}</h6>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
  };
}
export default connect(mapStateToProps, {
  setDose,
})(Emotions);