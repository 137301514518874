import React from "react";
import "./Frequecies.scss";

const Frequecies = (props) => {

  return (
    <div className="Frequecies">
      <div className={`${props.active ? "radio active" : "radio"}`}></div>
      <div className="name">{props.name}</div>
    </div>
  );
};

export default Frequecies;
