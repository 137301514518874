import React from "react";
import "./GoalRating.scss";

const GoalRating = (props) => {
  return (
    <div className="EmotionsCommentWrapper">
      {props.goal.map((item, i) => (
        <div
          className={`GoalRating ${props.rating === item.value ? "active" : ""}`}
          key={i}
          onClick={() => {
            props.onChange(item.value);
          }}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div className="radio">
              {props.rating === item.value && <div className="radio-inner" />}
            </div>
            <h6>{item.name}</h6>
          </div>
          <img src={props.rating === item.value ? item.activeImg : item.img} alt="" />
        </div>
      ))}
    </div>
  );
};

export default GoalRating;
