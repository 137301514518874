import React, { useState, useEffect } from "react";
import "./History.scss";
import { FiFilter } from "react-icons/fi";

// component
import InnerHeader from "../../components/template/innerHeader/InnerHeader";
import HistoryList from "../../components/history/HistoryList";
import Loader from "../../components/loader/Loader";

import { connect } from "react-redux";
import { fetchDoses } from "../../store/doses/actions";

import { getToken } from "../../utils/authManager";

const History = (props) => {
  const [doses, setDoses] = useState([]);
  const [doseType, setDoseType] = useState("");

  const loadData = async () => {
    try {
      const userId = await getToken();
      props.fetchDoses(userId);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (props.doses.length) {
      setDoses(props.doses);
    }
  }, [props.doses]);

  const handleFilter = () => {
    if (doseType) {
      setDoses([...props.doses.filter((dose) => dose.dose_type === doseType)]);
    } else {
      setDoses(props.doses);
    }
  };
  return (
    <div className="History">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        <InnerHeader text="Dose History" desktop={true} />
        <div className="mobile-wrapper ">
          <div className="d-flex justify-content-center align-items-center">
            <div className="input_wrapper pos-relative ">
              <select
                className="form-control"
                value={doseType}
                onChange={(e) => setDoseType(e.target.value)}
              >
                <option value="" selected disabled>
                  Select Dose
                </option>
                <option value="macro">Macro Dose</option>
                <option value="micro">Micro Dose</option>
              </select>
            </div>
            <button
              className="btn btn-outline-dark"
              type="button"
              onClick={() => handleFilter()}
            >
              <FiFilter /> Filter
            </button>
          </div>
          <div
            className="d-flex justify-content-center align-items-center flex-column  my-3"
            // style={{ zIndex: 9999 }}
          >
            {doses.map((item, i) => (
              <HistoryList key={i} item={item} />
            ))}
          </div>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose History" />
        <div className="mobile-wrapper">
          <div className="d-flex justify-content-start align-item-center">
            <div className="input_wrapper pos-relative ">
              <select
                className="form-control"
                value={doseType}
                onChange={(e) => setDoseType(e.target.value)}
              >
                <option value="" selected disabled>
                  Select Dose
                </option>
                <option value="macro">Macro Dose</option>
                <option value="micro">Micro Dose</option>
              </select>
            </div>
            <button
              className="btn btn-outline-dark"
              type="button"
              onClick={() => handleFilter()}
            >
              <FiFilter /> Filter
            </button>
          </div>
          <div
            className="d-flex justify-content-center align-items-center flex-column my-3"
            // style={{ zIndex: 9999 }}
          >
            {doses.map((item, i) => (
              <HistoryList key={i} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    doses: state.doses.doses,
    loading: state.doses.loading,
  };
}

export default connect(mapStateToProps, {
  fetchDoses,
})(History);
