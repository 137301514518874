import React, { useState } from "react";
import "./Login.scss";
import { FiEye, FiEyeOff, FiSend, FiLogIn } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { connect } from "react-redux";
import { setLogin } from "../../../store/auth/actions";
import validateLogin from "../../../validator/auth/login";
import { authService } from "../../../store/auth/services";
import { setToken } from "../../../utils/authManager";
import firebase from "../../../firebase/config";
const Login = (props) => {
  const [isPassword, setIsPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
  });
  const [errors, setErrors] = React.useState({});
  const handleLogin = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const { errors, isValid } = validateLogin(state);
    // check validations
    if (!isValid) {
      setErrors(errors);
      setState({ ...state, loading: false });
      return;
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .then(async (user) => {
        console.log("User account created & signed in!");
        await setToken(user.user.uid);
        await authService.register({
          email: state.email,
          userid: user.user.uid,
          phone: user.user.phoneNumber,
          isAnonymous: user.user.isAnonymous ? 1 : 0,
        });
        props.history.push("/home");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setErrors({ email: "Invalid email/password" });
        }
        if (error.code === "auth/invalid-email") {
          setErrors({ email: "That email address is invalid!" });
        }
        if (error.code === "auth/wrong-password") {
          setErrors({ password: "That password is invalid!" });
        }
        console.error(error);
      })
      .finally(() => {
        setState({ ...state, loading: false });
      });
  };
  return (
    <div className="Login">
      {/* only desktop version */}
      <div className="desktop-view">
        <form onSubmit={handleLogin} className="loginform">
          <img
            src="/images/bg/TOP_GREY_LAYERS.svg"
            alt=""
            className="top_wave"
          />
          <img
            src="/images/bg/BOTTOM_GREY_LAYERS.svg"
            alt=""
            className="bottom_wave"
          />
          <img src="/images/logo/logo.png" alt="" className="logo_desktop" />

          {/*  */}
          <div className="w-100 inner ">
            <h2 className="header text-left">Let’s Get Started</h2>
            {/* inputs */}
            <div className="input_wrapper pos-relative ">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                required
              />
            </div>
            {errors.email && <p className="text-danger">{errors.email}</p>}
            <div className="input_wrapper pos-relative ">
              <input
                type={`${isPassword ? "text" : "password"}`}
                className="form-control"
                placeholder="Password"
                value={state.password}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                required
              />
              {isPassword ? (
                <FiEyeOff
                  className="eye"
                  onClick={() => {
                    setIsPassword(!isPassword);
                  }}
                />
              ) : (
                <FiEye
                  className="eye"
                  onClick={() => {
                    setIsPassword(!isPassword);
                  }}
                />
              )}
            </div>
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
            <Link to="/forgot-password" className="forgot_password w-100">
              {" "}
              Forgot Password?{" "}
            </Link>
          </div>
          {/* buttons */}
          <button
            className="btn btn-outline-secondary"
            type="submit"
            disabled={state.loading}
          >
            {state.loading ? (
              "..."
            ) : (
              <>
                <FiSend /> Login
              </>
            )}
          </button>
          <div className="or font-weight-bold"> OR</div>
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              props.history.push("/signup");
            }}
          >
            <FiLogIn /> Sign Up
          </button>
          <div className="d-flex">
            <div className="social">
              <img src="/images/bg/FACEBOOK.svg" alt="" />
            </div>
            <div className="social">
              <img src="/images/bg/GOOGLE.svg" alt="" />
            </div>
          </div>
        </form>
      </div>

      {/* only the mobile version */}
      <div className="mobile-view">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        <img
          src="/images/bg/BOTTOM_GREY_LAYERS.svg"
          alt=""
          className="bottom_wave"
        />
        <form onSubmit={handleLogin}>
          <div className="d-flex flex-column justify-content-center align-items-center Login_form">
            {/* Logo */}
            <img src="/images/logo/logo.png" alt="" className="logo_mobile" />

            {/*  */}
            <div className="w-100 inner ">
              <h2 className="header text-left">Let’s Get Started</h2>
              {/* inputs */}
              <div className="input_wrapper pos-relative ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  required
                />
              </div>
              {errors.email && <p className="text-danger">{errors.email}</p>}
              <div className="input_wrapper pos-relative ">
                <input
                  type={`${isPassword ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Password"
                  value={state.password}
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  required
                />
                {isPassword ? (
                  <FiEyeOff
                    className="eye"
                    onClick={() => {
                      setIsPassword(!isPassword);
                    }}
                  />
                ) : (
                  <FiEye
                    className="eye"
                    onClick={() => {
                      setIsPassword(!isPassword);
                    }}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}
              <Link to="/forgot-password" className="forgot_password w-100">
                {" "}
                Forgot Password?{" "}
              </Link>
            </div>
            {/* buttons */}
            <button
              className="btn btn-outline-secondary"
              type="submit"
              disabled={state.loading}
            >
              {state.loading ? (
                "..."
              ) : (
                <>
                  <FiSend /> Login
                </>
              )}
            </button>
            <div className="or font-weight-bold"> OR</div>
            <button
              className="btn btn-outline-dark"
              onClick={() => {
                props.history.push("/signup");
              }}
            >
              <FiLogIn /> Sign Up
            </button>
            <div className="d-flex">
              <div className="social">
                <img src="/images/bg/FACEBOOK.svg" alt="" />
              </div>
              <div className="social">
                <img src="/images/bg/GOOGLE.svg" alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isLogin: state.auth.isLogin,
  };
}
export default connect(mapStateToProps, { setLogin })(Login);
