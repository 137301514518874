import React, { useState, useEffect } from "react";
import "./Step1.scss";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import Loader from "../../../components/loader/Loader";

import { connect } from "react-redux";
import { setDose, resetDose } from "../../../store/doses/actions";
import { fetchDrugs } from "../../../store/drugs/actions";

const RecordPastTripStep1 = (props) => {
  useEffect(() => {
    if(props.drugs.length === 0){
      props.fetchDrugs("Psychedelics");
    }
  }, [props.drugs])
  const handleSubmit = (e) => {
    e.preventDefault();
    props.history.push("/record-past-trip/step-2");
  };
  return (
    <div className="RecordPastTripStep1">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Record Past Trip" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 1: Fill the details in the below form</h1>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.dose_type}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_type: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="" disabled selected>
                      Type of dose
                    </option>
                    <option value="macro">Macro</option>
                    <option value="micro">Micro</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.dose.substance_type}
                    required
                  >
                    <option value="" selected disabled>
                      Choose substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-10">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    value={props.dose.amount_of_dose}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.unit_of_dose}
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        unit_of_dose: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      Kg
                    </option>
                    <option value="g">g</option>
                    <option value="μg">μg</option>
                    <option value="mg">mg</option>
                    <option value="tabs">tabs</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Enter Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Enter Time </label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Record Past Trip" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 1: Fill the details in the below form</h1>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.dose_type}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_type: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="" disabled selected>
                      Type of dose
                    </option>
                    <option value="macro">Macro</option>
                    <option value="micro">Micro</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.dose.substance_type}
                    required
                  >
                    <option value="" selected disabled>
                      Choose substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-8">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    value={props.dose.amount_of_dose}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.unit_of_dose}
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        unit_of_dose: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      Kg
                    </option>
                    <option value="g">g</option>
                    <option value="μg">μg</option>
                    <option value="mg">mg</option>
                    <option value="tabs">tabs</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Enter Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Enter Time </label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_time: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
    drugs: state.drugs.drugs,
    loading: state.drugs.loading,
  };
}
export default connect(mapStateToProps, {
  setDose,
  fetchDrugs,
  resetDose,
})(RecordPastTripStep1);
