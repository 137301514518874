import React from 'react';
import './CustomToolBar.scss';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const CustomToolBar = toolbar => {
  const goToBack = () => {
    let mDate = toolbar.date;
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    toolbar.onNavigate('prev', newDate);
  };
  const goToNext = () => {
    let mDate = toolbar.date;
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    toolbar.onNavigate('next', newDate);
  };
  return (
    <div className="CustomToolBar">
      <div
        className="prev"
        onClick={() => {
          goToBack();
        }}>
        <FiChevronLeft />
      </div>
      <div className="title">{toolbar.label}</div>
      <div
        className="next"
        onClick={() => {
          goToNext();
        }}>
        <FiChevronRight />
      </div>
    </div>
  );
};

export default CustomToolBar;
