import React, { useState, useEffect } from "react";
import "./Analitics.scss";
import { FiActivity } from "react-icons/fi";

// component
import Header from "../../components/template/header/Header";
import FlatAdds from "../../components/adds/flat/FlatAdds";

import { connect } from "react-redux";
import { fetchAnalyticalData } from "../../store/doses/actions";
import { fetchGoalHistory } from "../../store/goals/actions";
import { fetchAds } from "../../store/ads/actions";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";

import { getToken } from "../../utils/authManager";
import MoodBarChart from "./components/MoodBarChart";
import MoodEmotionLineChart from "./components/MoodEmotionLineChart";

const Analitics = (props) => {
  const [selectedMood, setSelectedMood] = useState("5");
  const [selectedEmotion, setSelectedEmotion] = useState("Any");
  const [showPage, setShowPage] = useState(false);
  const [personalGrowth, setPersonalGrowth] = useState(0);
  const [loading, setLoading] = useState(false);
  const loadData = async () => {
    setLoading(true);
    const userId = await getToken();
    await props.fetchAnalyticalData(userId);
    await props.fetchGoalHistory(userId);
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (props.analytics.hasOwnProperty("dose_history")) {
      if (props.analytics.dose_history.length) {
        setShowPage(true);
      }
    }
  }, [props.analytics]);
  useEffect(() => {
    if (props.goalHistory) {
      let growth = 0;
      if (props.goalHistory.length) {
        var startDate = moment(props.goalHistory[0].start_date);
        var endDate = moment(props.goalHistory[0].end_date);
        var totalDays = endDate.diff(startDate, "days");
        if (totalDays > 0) {
          let rating5Value = 100 / totalDays;
          let rating4Value = 100 / totalDays / 2;
          props.goalHistory.map((data) => {
            if (data.mood_rating == 5) {
              growth += rating5Value;
            } else if (data.mood_rating == 4) {
              growth += rating4Value;
            } else if (data.mood_rating == 2) {
              growth -= rating4Value;
            } else if (data.mood_rating == 1) {
              growth -= rating5Value;
            }
          });
          setPersonalGrowth(growth);
        }
      }
    }
  }, [props.goalHistory]);
  useEffect(() => {
    props.fetchAds();
    const interval = setInterval(() => {
      props.fetchAds();
    }, 80000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="analytics">
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <div className="mobile-wrapper">
          {/*  */}
          <div className="section">
            <h4>Person Growth</h4>
            <small>Track your personal psychedelic journey with goals</small>
            <div className="my-2 progress-bar-package">
              <CircularProgressbar
                value={personalGrowth}
                maxValue={100}
                text={`${parseInt(personalGrowth)}%`}
              />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="section mt-5 ">
            <h4>Mood Before/After</h4>
            <small>
              See how your mood has changed over the course of your psychedelic
              journey
            </small>
            <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-3">
              <div className="d-flex justify-content-start">
                <div className="pinkBatch mr-2"></div>
                <p className="mr-3">Before</p>
                <div className="violetBatch mr-2"></div>
                <p>After</p>
              </div>
              <div>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={selectedMood}
                  onChange={(e) => setSelectedMood(e.target.value)}
                >
                  <option value="5">Best 😀</option>
                  <option value="4">Better 😌</option>
                  <option value="3">Normal 😐</option>
                  <option value="2">Down 😕</option>
                  <option value="1">Worst 😖</option>
                </select>
              </div>
            </div>
            <div className="moodChart">
              <MoodBarChart mood={selectedMood} />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="section mt-5 ">
            <h4>Mood VS Emotions</h4>
            <small>How has your moods and emotions have affected you</small>
            <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-3">
              <div className="d-flex justify-content-start">
                <div className="pinkBatch mr-2"></div>
                <p className="mr-3 text-small">Before</p>
                <div className="violetBatch mr-2"></div>
                <p className="text-small">After</p>
              </div>
              <div>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={selectedEmotion}
                  onChange={(e) => setSelectedEmotion(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Happy">Happy</option>
                  <option value="Angry">Angry</option>
                  <option value="Sad">Sad</option>
                  <option value="Loved">Loved</option>
                  <option value="Calm">Calm</option>
                  <option value="Anxiety">Anxiety</option>
                  <option value="Confused">Confused</option>
                  <option value="Bored">Bored</option>
                  <option value="Scared">Scared</option>
                </select>
              </div>
            </div>
            <div className="MoodEmotionLineChart">
              <MoodEmotionLineChart emotion={selectedEmotion} />
            </div>
          </div>
          {/*  */}
          {props.ad?.id && (
          <FlatAdds />
          )}
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* haeder */}
        <Header icon={<FiActivity />} text="Analytics" />
        <div className="mobile-wrapper">
          {/*  */}
          <div className="section">
            <h4>Person Growth</h4>
            <small>Track your personal psychedelic journey with goals</small>
            <div className="my-2 progress-bar-package">
              <CircularProgressbar
                value={personalGrowth}
                maxValue={100}
                text={`${parseInt(personalGrowth)}%`}
              />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="section">
            <h4>Mood Before/After</h4>
            <small>
              See how your mood has changed over the course of your psychedelic
              journey
            </small>
            <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-3">
              <div className="d-flex justify-content-start">
                <div className="pinkBatch mr-2"></div>
                <p className="mr-3 text-small">Before</p>
                <div className="violetBatch mr-2"></div>
                <p className="text-small">After</p>
              </div>
              <div>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={selectedMood}
                  onChange={(e) => setSelectedMood(e.target.value)}
                >
                  <option value="5">Best 😀</option>
                  <option value="4">Better 😌</option>
                  <option value="3">Normal 😐</option>
                  <option value="2">Down 😕</option>
                  <option value="1">Worst 😖</option>
                </select>
              </div>
            </div>
            <div className="moodChart">
              <MoodBarChart mood={selectedMood} />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="section">
            <h4>Mood VS Emotions</h4>
            <small>How has your moods and emotions have affected you</small>
            <div className="d-flex justify-content-between align-items-center px-3 mt-3 mb-3">
              <div className="d-flex justify-content-start">
                <div className="pinkBatch mr-2"></div>
                <p className="mr-3 text-small">Before</p>
                <div className="violetBatch mr-2"></div>
                <p className="text-small">After</p>
              </div>
              <div>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={selectedEmotion}
                  onChange={(e) => setSelectedEmotion(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Happy">Happy</option>
                  <option value="Angry">Angry</option>
                  <option value="Sad">Sad</option>
                  <option value="Loved">Loved</option>
                  <option value="Calm">Calm</option>
                  <option value="Anxiety">Anxiety</option>
                  <option value="Confused">Confused</option>
                  <option value="Bored">Bored</option>
                  <option value="Scared">Scared</option>
                </select>
              </div>
            </div>
            <div className="MoodEmotionLineChart">
              <MoodEmotionLineChart emotion={selectedEmotion} />
            </div>
          </div>
          {/*  */}
          {props.ad?.id && (
            <FlatAdds />
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    analytics: state.doses.analytics,
    loading: state.doses.loading,
    goalHistory: state.goals.goalHistory,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  fetchAnalyticalData,
  fetchGoalHistory,
  fetchAds
})(Analitics);
