import React from "react";
import "./Dosage.scss";
import { Link } from "react-router-dom";
import { FiCheck, FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { PieChart } from "react-minimal-pie-chart";

const Dosage = (props) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Duration</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom Dosage">
          {props.name} <br />
            <div className="infoColors">
              <div className="box Light">
                <div className="bg"></div>
                <div className="">Light({props.dose.hasOwnProperty("light") ? `${props.dose.light?.min}${props.dose?.units} - ${props.dose.light?.max}${props.dose?.units}` : ""})</div>
              </div>
              <div className="box Common">
                <div className="bg"></div>
                <div className="">Common({props.dose.hasOwnProperty("common") ? `${props.dose.common?.min}${props.dose?.units} - ${props.dose.common?.max}${props.dose?.units}` : ""})</div>
              </div>
              <div className="box Strong">
                <div className="bg"></div>
                <div className="">Strong({props.dose.hasOwnProperty("strong") ? `${props.dose.strong?.min}${props.dose?.units} - ${props.dose.strong?.max}${props.dose?.units}` : ""})</div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-6">
                <PieChart
                  data={[
                    { title: "One", value: props.dose.hasOwnProperty("light") ? props.dose.light?.max - props.dose.light?.min: 0, color: "#35EB31" },
                    { title: "Two", value: props.dose.hasOwnProperty("common") ? props.dose.common?.max - props.dose.common?.min: 0, color: "#663DB9" },
                    { title: "Three", value: props.dose.hasOwnProperty("strong") ? props.dose.strong?.max - props.dose.strong?.min: 0, color: "#FC3636" },
                  ]}
                />
              </div>
              <div className="col-6">
                This dosage information is gathered from users andresources for
                educational purposes only. it is not arecommendation and should
                be verified with othersources for accuracy.
              </div>
            </div>
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Dosage;
