import React from "react";
import "./ScheduleHistoryItem.scss";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";

import { connect } from "react-redux";
import { deleteScheduleDose } from "../../store/doses/actions";
import { getToken } from "../../utils/authManager";
import {useHistory} from "react-router-dom";
import Swal from "sweetalert2";

const ScheduleHistoryItem = (props) => {
  const history = useHistory();
  const [show, setShow] = React.useState(true);
  const handleDeleteSchedule = async () => {
    Swal.fire({
      title: "Are you sure want to delete this schedule ?",
      showCancelButton: true,
      confirmButtonText: `OK`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const userId = await getToken();
        props.deleteScheduleDose(props.item.id, userId);
      }
    });
  };
  return (
    <div className="ScheduleHistoryItem">
      <div
        className="d-flex justify-content-between align-items-center p-2 desk-bg "
        onClick={() => {
          setShow(!show);
        }}
      >
        <h6 className="m-0">Created On</h6>
        <div className="d-flex justify-content-end align-items-center">
          <h6 className="m-0">{props.item.created_on}</h6>
          {show ? (
            <FiArrowUpCircle className="ml-2" />
          ) : (
            <FiArrowDownCircle className="ml-2" />
          )}
        </div>
      </div>
      {show && (
        <>
          <div className="desktop-only">
            <div className="details p-2">
              <div className="row">
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Substance</h6>
                    <h6 className="right">{props.item.substance_type}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Amount of Dose</h6>
                    <h6 className="right">{props.item.amount_of_dose} {props.item.unit_of_dose}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Number of Dose</h6>
                    <h6 className="right">{props.item.number_of_dose}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Start Date</h6>
                    <h6 className="right">{props.item.start_date}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item column">
                    <h6 className="left">End Date</h6>
                    <h6 className="right">{props.item.end_date}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item column">
                    <h6 className="left">Days On</h6>
                    <h6 className="right">{props.item.days_on}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item column">
                    <h6 className="left">Days Off</h6>
                    <h6 className="right">{props.item.days_off}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item column">
                    <h6 className="left">Frequency</h6>
                    <h6 className="right">{props.item.frequency || "Custom"}</h6>
                  </div>
                </div>
              </div>

              <div className="item">
                <button
                  className="btn btn-outline-light w-50"
                  onClick={() => handleDeleteSchedule()}
                >
                  Delete
                </button>
                <button className="btn btn-light w-50" onClick={()=>{
                  history.push(`/schedule-dose/step-1/${props.item.id}`)
                }}>Edit</button>
              </div>
            </div>
          </div>
          <div className="mobile-only">
            <div className="details p-2">
              <div className="item">
                <h6 className="left">Substance</h6>
                <h6 className="right">{props.item.substance_type}</h6>
              </div>
              <div className="item">
                <h6 className="left">Amount of Dose</h6>
                <h6 className="right">{props.item.amount_of_dose} {props.item.unit_of_dose}</h6>
              </div>
              <div className="item">
                <h6 className="left">Number of Dose</h6>
                <h6 className="right">{props.item.number_of_dose}</h6>
              </div>
              <div className="item">
                <h6 className="left">Start Date</h6>
                <h6 className="right">{props.item.start_date}</h6>
              </div>
              <div className="item column">
                <h6 className="left">End Date</h6>
                <h6 className="right">{props.item.end_date}</h6>
              </div>
              <div className="item column">
                <h6 className="left">Days On</h6>
                <h6 className="right">{props.item.days_on}</h6>
              </div>
              <div className="item column">
                <h6 className="left">Days Off</h6>
                <h6 className="right">{props.item.days_off}</h6>
              </div>
              <div className="item column">
                <h6 className="left">Frequency</h6>
                <h6 className="right">{props.item.frequency || "Custom"}</h6>
              </div>
              <div className="item">
                <button
                  className="btn btn-outline-light w-50"
                  onClick={() => handleDeleteSchedule()}
                >
                  Delete
                </button>
                <button className="btn btn-light w-50" onClick={()=>{
                  history.push(`/schedule-dose/step-1/${props.item.id}`)
                }}>Edit</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
    deleteScheduleDose,
})(ScheduleHistoryItem);
