import React, { useEffect } from "react";
import "./DrugsListing.scss";
import { FiThermometer, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";

// component
import Header from "../../components/template/header/Header";
import Loader from "../../components/loader/Loader";
import FlatAdds from "../../components/adds/flat/FlatAdds";

import { connect } from "react-redux";
import { fetchDrugs } from "../../store/drugs/actions";
import { fetchAds } from "../../store/ads/actions";

const DrugsListing = (props) => {
  const [drugs, setDrugs] = React.useState([]);
  useEffect(() => {
    if (props.drugs.length === 0) {
      props.fetchDrugs("Psychedelics");
    }
  }, [props.drugs]);
  useEffect(() => {
    if (props.drugs.length) {
      setDrugs([...props.drugs]);
    } else {
      setDrugs([]);
    }
  }, [props.drugs]);
  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      let filteredDrugs = props.drugs.filter((drug) =>
        drug.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDrugs([...filteredDrugs]);
    } else {
      setDrugs([...props.drugs]);
    }
  };
  useEffect(() => {
    props.fetchAds();
    const interval = setInterval(() => {
      props.fetchAds();
    }, 80000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="drugListing">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* bg waves */}
        <div className="search-wrapper">
          <FiSearch className="search-icon" />
          <input
            type="text"
            name=""
            id=""
            placeholder="search"
            className="search-input"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        {/* listing */}
        <div className="list">
          {drugs.map((item, i) => (
            <>
              {/* {i / 5 === 0 ? (
                <FlatAdds noMB />
              ) : ( */}
              <Link to={`/drug-detail/${item.name}`} className="item" key={i}>
                <div className="h6">{item.name}</div>
                {/* <small>{item.class ? [...item.class?.chemical || [], ...item.class?.psychoactive || []].join(', ') : ''}</small> */}
              </Link>
              {/* )} */}
            </>
          ))}
        </div>
        {props.ad?.id && (
        <FlatAdds />
        )}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        <Header icon={<FiThermometer />} text="Drug List" />

        {/* bg waves */}
        <div className="search-wrapper">
          <FiSearch className="search-icon" />
          <input
            type="text"
            name=""
            id=""
            placeholder="search"
            className="search-input"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        {/* listing */}
        <div className="list">
          {drugs.map((item, i) => (
            <>
              <Link to={`/drug-detail/${item.name}`} className="item" key={i}>
                <div className="h6">{item.name}</div>
                {/* <small>{item.class ? [...item.class?.chemical || [], ...item.class?.psychoactive || []].join(', ') : ''}</small> */}
              </Link>
            </>
          ))}
        </div>
        {props.ad?.id && (
        <FlatAdds />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    drugs: state.drugs.drugs,
    loading: state.drugs.loading,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  fetchDrugs,
  fetchAds
})(DrugsListing);
