import React from "react";
import "./TopNotification.scss";
import { FiX } from "react-icons/fi";

import { connect } from "react-redux";
import {
  setShowNotification,
} from "../../../store/doses/actions";
import {Link} from "react-router-dom";

const TopNotification = (props) => {
  return (
    <Link to={`/mid-trip/step-1/${props.notification.doseId}/${props.notification.durationEvent}`} style={{textDecoration: "none"}}>
      <div className="TopNotification">
        <div className="left">
          <small>{props.notification.duration} {props.notification.units}</small>
          <div className="d-flex">
            <h4>{props.notification.title}</h4>
            <img src="/images/status/pill.png" alt="" />
          </div>
          <small>{props.notification.description}</small>
        </div>
        <div className="right" onClick={()=>{
          props.setShowNotification(false)
        }}>
          <FiX />
        </div>
      </div>
    </Link>
  );
};
function mapStateToProps(state) {
  return {
    showNotification: state.doses.showNotification,
    notification: state.doses.notification,
  };
}

export default connect(mapStateToProps, {
  setShowNotification,
})(TopNotification);