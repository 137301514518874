import React, { useState } from "react";
import "./ForgotPassword.scss";
import { FiSend, FiArrowLeft } from "react-icons/fi";
import { connect } from "react-redux";
import { setLogin } from "../../../store/auth/actions";
import firebase from "../../../firebase/config";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = (props) => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const [state, setState] = useState({
    email: "",
    loading: false,
  });
  const [errors, setErrors] = React.useState({});
  const handleForgetPassword = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    firebase
      .auth()
      .sendPasswordResetEmail(state.email)
      .then(() => {
        notifySuccess("Please check your email to change password")
      })
      .catch((error) => {
       if(error.code === "auth/user-not-found"){
        notifyError("No user found with this email")
       }
      })
      .finally(() => {
        setState({ ...state, loading: false });
      });
  };
  return (
    <div className="ForgotPassword">
      {/* only desktop version */}
      <div className="desktop-view">
        <form onSubmit={handleForgetPassword} className="loginform">
          <img
            src="/images/bg/TOP_GREY_LAYERS.svg"
            alt=""
            className="top_wave"
          />
          <img
            src="/images/bg/BOTTOM_GREY_LAYERS.svg"
            alt=""
            className="bottom_wave"
          />
          <img src="/images/logo/logo.png" alt="" className="logo_desktop" />

          {/*  */}
          <div className="w-100 inner ">
            <h2 className="header text-left">Forgot Password</h2>
            {/* inputs */}
            <div className="input_wrapper pos-relative ">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                required
              />
            </div>
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          {/* buttons */}
          <button
            className="btn btn-outline-secondary"
            type="submit"
            disabled={state.loading}
          >
            {state.loading ? (
              "..."
            ) : (
              <>
                <FiSend /> Send Email
              </>
            )}
          </button>
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <FiArrowLeft /> Back
          </button>
        </form>
      </div>
      {/* only the mobile version */}
      <div className="mobile-view">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        <img
          src="/images/bg/BOTTOM_GREY_LAYERS.svg"
          alt=""
          className="bottom_wave"
        />
        <form onSubmit={handleForgetPassword}>
          <div className="d-flex flex-column justify-content-center align-items-center Login_form">
            {/* Logo */}
            <img src="/images/logo/logo.png" alt="" className="logo_mobile" />

            {/*  */}
            <div className="w-100 inner ">
              <h2 className="header text-left">Forgot Password</h2>
              {/* inputs */}
              <div className="input_wrapper pos-relative ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  required
                />
              </div>
              {errors.email && <p className="text-danger">{errors.email}</p>}
            </div>
            {/* buttons */}
            <button
              className="btn btn-outline-secondary"
              type="submit"
              disabled={state.loading}
            >
              {state.loading ? (
                "..."
              ) : (
                <>
                  <FiSend /> Send Email
                </>
              )}
            </button>
            <button
              className="btn btn-outline-dark"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <FiArrowLeft /> Back
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isLogin: state.auth.isLogin,
  };
}
export default connect(mapStateToProps, { setLogin })(ForgotPassword);
