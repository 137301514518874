import React, {useEffect} from "react";
import "./GoalSet.scss";

import {connect} from 'react-redux';
import {setActiveGoal} from "../../../store/goals/actions";

const GoalSet = (props) => {
  const [active, setActive] = React.useState('');
  const handleSetOption = (item) =>{
    props.setActiveGoal(item.id);
    setActive(item.name);
  }
  useEffect(()=>{
    if(props.goals.length){
      if(props.activeGoal){
        let selectedGoal = props.goals.filter(goal =>goal.id === props.activeGoal);
        if(selectedGoal.length){
          setActive(selectedGoal[0].name)
        }
      }
    }
  }, [props.goals, props.activeGoal])
  return (
    <div className="GoalSetWrapper">
      {props.goal.map((item, i) => (
        <div
          className={`GoalSet ${active === item.name ? "active" : ""}`}
          key={i}
          onClick={() => {
            handleSetOption(item)
          }}
        >
          <div className="radio">
            {active === item.name && <div className="radio-inner" />}
          </div>
          <h6>{item.name}</h6>
        </div>
      ))}
    </div>
  );
};
function mapStateToProps(state) {return {
  activeGoal: state.goals.activeGoal,
  goals: state.goals.goals,
};}

export default connect(mapStateToProps, {
  setActiveGoal
})(GoalSet);