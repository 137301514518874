import React from "react";
import { Switch, Redirect, HashRouter } from "react-router-dom";
import RouteWithLayout from "./components/routeWithLayout/RouteWithLayout";
import MainView from "./layout/main/MainView";
import MinimalView from "./layout/minimal/MinimalView";

// imported Screens
// auths
import Login from "./views/auth/login/Login";
import Signup from "./views/auth/signup/Signup";
import ForgotPassword from "./views/auth/forgotPassword/ForgotPassword";

// tabs
import Home from "./views/home/Home";
import Analitics from "./views/analitics/Analitics";
import Profile from "./views/profile/Profile";
import DrugsListing from "./views/drugsListing/DrugsListing";
import CreateDose from "./views/createDose/CreateDose";

// doseNow
import DoseNowStep1 from "./views/doseNow/step1/Step1";
import DoseNowStep2 from "./views/doseNow/step2/Step2";
import DoseNowStep3 from "./views/doseNow/step3/Step3";
import DoseNowStep4 from "./views/doseNow/step4/Step4";

// RecordPastTrip
import RecordPastTripStep1 from "./views/recordPastTrip/step1/Step1";
import RecordPastTripStep2 from "./views/recordPastTrip/step2/Step2";
import RecordPastTripStep3 from "./views/recordPastTrip/step3/Step3";
import RecordPastTripStep4 from "./views/recordPastTrip/step4/Step4";

// schedule dose
import ScheduleDoseStep1 from "./views/scheduleDose/step1/Step1";
import ScheduleDoseStep2 from "./views/scheduleDose/step2/Step2";

// GoalStep3
import GoalStep1 from "./views/goal/step1/Step1";
import GoalStep2 from "./views/goal/step2/Step2";
import GoalStep3 from "./views/goal/step3/Step3";

//inner
import History from "./views/history/History";
import EditProfile from "./views/editProfile/EditProfile";
import ChangePassword from "./views/changePassword/ChangePassword";
import DrugDetail from "./views/drugDetail/DrugDetail";
import ScheduleHistory from "./views/scheduleHistory/ScheduleHistory";

// recordDoseAfter
import RecordDoseAfter1 from "./views/recordDoseAfter/step1/Step1";
import RecordDoseAfter2 from "./views/recordDoseAfter/step2/Step2";
import RecordDoseAfter3 from "./views/recordDoseAfter/step3/Step3";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
      {/* <Redirect from="/" to="/login" /> */}
      {/* auths */}
      <RouteWithLayout
        exact
        path="/"
        component={Login}
        layout={MinimalView}
        access="public"
        restricted={true}
      />
      <RouteWithLayout
        exact
        path="/login"
        component={Login}
        layout={MinimalView}
        access="public"
        restricted={true}
      />
      <RouteWithLayout
        exact
        path="/signup"
        component={Signup}
        layout={MinimalView}
        access="public"
        restricted={true}
      />
      <RouteWithLayout
        exact
        path="/forgot-password"
        component={ForgotPassword}
        layout={MinimalView}
        access="public"
        restricted={true}
      />

      {/* tabs */}
      <RouteWithLayout
        exact
        path="/home"
        component={Home}
        layout={MainView}
        access="public"
        // access="private"
      />
      <RouteWithLayout
        exact
        path="/analitics"
        component={Analitics}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/profile"
        component={Profile}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/drug-listing"
        component={DrugsListing}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/create-dose"
        component={CreateDose}
        layout={MainView}
        access="private"
      />

      {/* dose Now */}
      <RouteWithLayout
        exact
        path="/schedule-dose/step-1/:id?"
        component={ScheduleDoseStep1}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/schedule-dose/step-2/:id?"
        component={ScheduleDoseStep2}
        layout={MainView}
        access="private"
      />

      {/* dose Now */}
      <RouteWithLayout
        exact
        path="/dosenow/step-1/:id?"
        component={DoseNowStep1}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/dosenow/step-2/:id?"
        component={DoseNowStep2}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/dosenow/step-3/:id?"
        component={DoseNowStep3}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/dosenow/step-4/:id?"
        component={DoseNowStep4}
        layout={MainView}
        access="private"
      />

      {/* RecordPastTripNow */}
      <RouteWithLayout
        exact
        path="/record-past-trip/step-1"
        component={RecordPastTripStep1}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/record-past-trip/step-2"
        component={RecordPastTripStep2}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/record-past-trip/step-3"
        component={RecordPastTripStep3}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/record-past-trip/step-4"
        component={RecordPastTripStep4}
        layout={MainView}
        access="private"
      />

      {/* Goal */}
      <RouteWithLayout
        exact
        path="/goal/step-1"
        component={GoalStep1}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/goal/step-2"
        component={GoalStep2}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/goal/step-3/:id"
        component={GoalStep3}
        layout={MainView}
        access="private"
      />

      {/* inner */}
      <RouteWithLayout
        exact
        path="/history"
        component={History}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/schedule-history"
        component={ScheduleHistory}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/edit-profile"
        component={EditProfile}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/change-password"
        component={ChangePassword}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/drug-detail/:name"
        component={DrugDetail}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/mid-trip/step-1/:doseid/:duration"
        component={RecordDoseAfter1}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/mid-trip/step-2/:doseid/:duration"
        component={RecordDoseAfter2}
        layout={MainView}
        access="private"
      />
      <RouteWithLayout
        exact
        path="/mid-trip/step-3/:doseid/:duration"
        component={RecordDoseAfter3}
        layout={MainView}
        access="private"
      />
    </Switch>
    </HashRouter>
  );
};
export default Routes;
