import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="Loader">
      <div className="spinner-border text-light spinner" role="status">
        {/* <span class="visually-hidden">Loading...</span> */}
      </div>
    </div>
  );
};

export default Loader;
