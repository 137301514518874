import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
var config = {
    apiKey: "AIzaSyAceFg0j3o1qCBSX1Tk0Z1oS1r3XTrj0ls",
    authDomain: "tunein-2b6e9.firebaseapp.com",
    databaseURL: "https://tunein-2b6e9.firebaseio.com",
    projectId: "tunein-2b6e9",
    storageBucket: "tunein-2b6e9.appspot.com",
    messagingSenderId: "1016536293174",
    appId: "1:1016536293174:web:1240ba82a921914a571d97",
    measurementId: "G-FSBB1P63MM"
};
firebase.initializeApp(config);
firebase.analytics();
export default firebase;