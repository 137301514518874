import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CanvasJSReact from "../../../dist/canvasjs/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const MoodBarChart = (props) => {
  const [options, setOption] = useState({
    animationEnabled: true,
    theme: "light2",
    data: [
      {
        type: "bar",
        dataPoints: [],
      },
    ],
  });
  useEffect(() => {
    if (props.analytics) {
      let doseHistory = props.analytics.dose_history;
      let dosePastRecords = props.analytics.dose_past_records;
      let moodBeforeCount = 0;
      let moodAfterCount = 0;

      if (doseHistory) {
        let filteredDoseHistory = doseHistory.filter(
          (dose) => dose.mood_before_dose === props.mood,
        );
        if (filteredDoseHistory.length) {
          filteredDoseHistory.map((dose) => {
            moodBeforeCount += parseInt(dose.mood_before_dose);
          });
        }
      }
      if (dosePastRecords) {
        let filteredDosePastRecords = dosePastRecords.filter(
          (dose) => dose.mood_after_dose === props.mood,
        );
        if (filteredDosePastRecords.length) {
          filteredDosePastRecords.map((dose) => {
            moodAfterCount += parseInt(dose.mood_after_dose);
          });
        }
      }
      setOption({
        ...options,
        data:[{
          ...options.data[0],
          dataPoints: [
            { y: moodAfterCount, label: "After", color: "#663DB9" },
            { y: moodBeforeCount, label: "Before", color: "#FF43CA" },
          ],
        }]
      })
    }
  }, [props.analytics, props.mood]);
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    analytics: state.doses.analytics,
  };
}

export default connect(mapStateToProps, {})(MoodBarChart);
