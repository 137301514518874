import React, { useEffect, useState } from "react";
import "./AdModal.scss";
import { connect } from "react-redux";
import { incrementClick, fetchAds } from "../../../store/ads/actions";
import { UPLOAD_URL } from "../../../config/";
import { FiX } from "react-icons/fi";

const AdModal = (props) => {
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    props.fetchAds();
  }, []);
  const handleClickAd = () => {
    if (props.ad.id) {
      props.incrementClick(props.ad.id);
      if (props.ad.link) {
        window.open(
          props.ad.link?.indexOf("://") === -1
            ? "http://" + props.ad.link
            : props.ad.link,
          "_blank"
        );
      }
    }
  };
  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        setShowCloseButton(true);
      }, 5000);
    }
  }, [props.visible]);
  return (
    <div
      className={`AdModal ${props.visible === true ? "show" : ""} `}
      onClick={props.close}
    >
      <div className="content">
        <div className="body">
          <div className="pos-relative">
            {showCloseButton && (
              <div className="close" onClick={props.close}>
                <FiX />
              </div>
            )}
            <div
              onClick={() => {
                handleClickAd();
              }}
            >
              <h4>Ad:</h4>
              <img src={`${UPLOAD_URL}${props.ad?.portrait_media}`} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ad: state.ads.ad,
  };
}
export default connect(mapStateToProps, {
  incrementClick,
  fetchAds,
})(AdModal);
