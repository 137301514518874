import React, { useEffect } from "react";
import "./Step1.scss";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import Frequecies from "../../../components/scheduleDose/frequecies/Frequecies";
import Loader from "../../../components/loader/Loader";

import { connect } from "react-redux";
import { fetchDrugs } from "../../../store/drugs/actions";
import {
  setScheduleDose,
  resetScheduleDose,
  fetchScheduleDetails
} from "../../../store/doses/actions";

const ScheduleDoseStep1 = (props) => {
  const frequency = [
    { name: "Stamets Protocol 5/2", daysOn: 5, daysOff: 2 },
    { name: "Fadiman Protocol 1/3", daysOn: 1, daysOff: 3 },
    { name: "Fadiman Protocol 1/2", daysOn: 1, daysOff: 2 },
    { name: "Aternating Protocol 1/1", daysOn: 1, daysOff: 1 },
    { name: "PT Protocol 1/3", daysOn: 1, daysOff: 3 },
  ];
  const [active, setActive] = React.useState(null);
  useEffect(() => {
    if(props?.match?.params?.id){
      const {id} = props.match.params
      props.fetchScheduleDetails(id)
    }else{
      props.resetScheduleDose();
    }
    props.fetchDrugs("Psychedelics");
  }, []);
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(props?.match?.params?.id){
      props.history.push(`/schedule-dose/step-2/${props?.match?.params?.id}`);
    }else{
      props.history.push(`/schedule-dose/step-2`);
    }
  }
  return (
    <div className="ScheduleDoseStep1">
      {props.loading && <Loader />}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Schedule Dose" desktop={true} />
        <div className="mobile-wrapper">
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.schedule_dose.substance_type}
                    required
                  >
                    <option value="" disabled selected>
                      Type of substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-8">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    value={props.schedule_dose.amount_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.schedule_dose.unit_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        unit_of_dose: e.target.value,
                      })
                    }
                  >
                    <option value="g" selected>
                      g
                    </option>
                    <option value="μg" selected>
                      μg
                    </option>
                    <option value="mg" selected>
                      mg
                    </option>
                    <option value="tabs" selected>
                      tabs
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Number of dose"
                    required
                    value={props.schedule_dose.number_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        number_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Start Date </label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    value={props.schedule_dose.start_date}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        start_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> End Date </label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    value={props.schedule_dose.end_date}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        end_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor="">Choose the micro dose frequency</label>
                </div>
                <div className="Frequecies-wrapper ">
                  {frequency.map((item, i) => (
                    <div
                      onClick={() => {
                        // setActive(i === active ? null : i);
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          frequency: item.name,
                          days_on: item.daysOn,
                          days_off: item.daysOff,
                        });
                      }}
                    >
                      <Frequecies
                        name={item.name}
                        active={props.schedule_dose.frequency === item.name}
                        key={i}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 my-3 ">
                <h6 className="text-center">OR</h6>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        days_on: e.target.value,
                        frequency: "",
                      })
                    }
                  >
                    <option value="" selected disabled>
                      Days on
                    </option>
                    {["1", "2", "3", "4", "5", "6", "7"].map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onValueChange={(itemValue, itemIndex) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        days_off: itemValue,
                        frequency: "",
                      })
                    }
                  >
                    <option value="" selected disabled>
                      Days Off
                    </option>
                    {["1", "2", "3", "4", "5", "6", "7"].map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Schedule Dose" />
        <div className="mobile-wrapper">
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.schedule_dose.substance_type}
                    required
                  >
                    <option value="" disabled selected>
                      Type of substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-8">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={props.schedule_dose.amount_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.schedule_dose.unit_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        unit_of_dose: e.target.value,
                      })
                    }
                  >
                    <option value="g" selected>
                      g
                    </option>
                    <option value="μg" selected>
                      μg
                    </option>
                    <option value="mg" selected>
                      mg
                    </option>
                    <option value="tabs" selected>
                      tabs
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Number of dose"
                    required
                    value={props.schedule_dose.number_of_dose}
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        number_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Start Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        start_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> End Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        end_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor="">Choose the micro dose frequency</label>
                </div>
                <div className="Frequecies-wrapper ">
                  {frequency.map((item, i) => (
                    <div
                      onClick={() => {
                        // setActive(i === active ? null : i);
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          frequency: item.name,
                          days_on: item.daysOn,
                          days_off: item.daysOff,
                        });
                      }}
                    >
                      <Frequecies
                        name={item.name}
                        active={props.schedule_dose.frequency === item.name}
                        key={i}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12">
                <h6 className="text-center">OR</h6>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        days_on: e.target.value,
                        frequency: "",
                      })
                    }
                  >
                    <option value="" selected disabled>
                      Days on
                    </option>
                    {["1", "2", "3", "4", "5", "6", "7"].map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onValueChange={(itemValue, itemIndex) =>
                      props.setScheduleDose({
                        ...props.schedule_dose,
                        days_off: itemValue,
                        frequency: "",
                      })
                    }
                  >
                    <option value="" selected disabled>
                      Days Off
                    </option>
                    {["1", "2", "3", "4", "5", "6", "7"].map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    drugs: state.drugs.drugs,
    schedule_dose: state.doses.schedule_dose,
    loading: state.drugs.loading,
  };
}

export default connect(mapStateToProps, {
  fetchDrugs,
  setScheduleDose,
  resetScheduleDose,
  fetchScheduleDetails
})(ScheduleDoseStep1);
