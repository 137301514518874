import axios from "axios";
import { API_URL } from "../../config/";

function register(formData) {
  return new Promise(async (resolve, reject) => {
    axios
    .post(`${API_URL}auth/register`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export const authService = {
  register,
};
