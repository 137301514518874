import React from "react";
import "./Effects.scss";
import { Link } from "react-router-dom";
import { FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const Effects = (props) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Effects</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom">
          {props.effects.map(effect => effect.name).slice(0, 20).join(", ")}
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Effects;
