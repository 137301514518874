import React, { useState } from "react";
import "./Signup.scss";
import styled from "styled-components";
import { FiEye, FiEyeOff, FiSend, FiLogIn } from "react-icons/fi";
import { Link } from "react-router-dom";
import validateSignup from "../../../validator/auth/signup";
import firebase from "../../../firebase/config";
import { setToken } from "../../../utils/authManager";
import { authService } from "../../../store/auth/services";

const Signup = (props) => {
  const [isPassword, setIsPassword] = React.useState(false);
  const [isPassword2, setIsPassword2] = React.useState(false);
  const [state, setState] = useState({
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    loading: false,
  });
  const [errors, setErrors] = React.useState({});
  const handleSignUp = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const { errors, isValid } = validateSignup(state);
    // check validations
    if (!isValid) {
      setErrors(errors);
      setState({ ...state, loading: false });
      return;
    }
    firebase
      .auth()
      .createUserWithEmailAndPassword(state.email, state.password)
      .then(async (user) => {
        console.log("User account created & signed in!");
        await setToken(user.user.uid);
        await authService.register({
          email: state.email,
          userid: user.user.uid,
          phone: state.phone,
          isAnonymous: user.user.isAnonymous ? 1 : 0,
        });
        props.history.push("/home");
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setErrors({ email: "That email address is already in use!" });
        }
        if (error.code === "auth/invalid-email") {
          setErrors({ email: "That email address is invalid!" });
        }
        console.error(error);
      })
      .finally(() => {
        setState({ ...state, loading: false });
      });
  };
  return (
    <div className="Signup">
      {/* only desktop version */}
      <div className="desktop-view">
        <form onSubmit={handleSignUp} className="signupform">
          <img
            src="/images/bg/TOP_GREY_LAYERS.svg"
            alt=""
            className="top_wave"
          />
          <img
            src="/images/bg/BOTTOM_GREY_LAYERS.svg"
            alt=""
            className="bottom_wave"
          />
          <img src="/images/logo/logo.png" alt="" className="logo_desktop" />
          <div className="w-100 inner ">
            <h2 className="header text-left">Let’s Get Start</h2>
            {/* inputs */}
            <div className="input_wrapper pos-relative ">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                required
              />
            </div>
            {errors.email && <p className="text-danger">{errors.email}</p>}
            <div className="input_wrapper pos-relative ">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
                required
              />
            </div>
            {errors.phone && <p className="text-danger">{errors.phone}</p>}
            <div className="input_wrapper pos-relative ">
              <input
                type={`${isPassword ? "text" : "password"}`}
                className="form-control"
                placeholder="Password"
                value={state.password}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                required
              />
              {isPassword ? (
                <FiEyeOff
                  className="eye"
                  onClick={() => {
                    setIsPassword(!isPassword);
                  }}
                />
              ) : (
                <FiEye
                  className="eye"
                  onClick={() => {
                    setIsPassword(!isPassword);
                  }}
                />
              )}
            </div>
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
            <div className="input_wrapper pos-relative ">
              <input
                type={`${isPassword2 ? "text" : "password"}`}
                className="form-control"
                placeholder="Confirm Password"
                value={state.confirmPassword}
                onChange={(e) =>
                  setState({ ...state, confirmPassword: e.target.value })
                }
                required
              />
              {isPassword ? (
                <FiEyeOff
                  className="eye"
                  onClick={() => {
                    setIsPassword2(!isPassword2);
                  }}
                />
              ) : (
                <FiEye
                  className="eye"
                  onClick={() => {
                    setIsPassword2(!isPassword2);
                  }}
                />
              )}
            </div>
            {errors.confirmPassword && (
              <p className="text-danger">{errors.confirmPassword}</p>
            )}
            <div className="input-wrapper d-flex mb-4 align-items-center ">
              <input type="checkbox" name="" id="" className="mr-3" required />
              <span>
                Agree to our <Link to="/terms"> terms of services</Link>{" "}
              </span>
            </div>
          </div>

          {/* buttons */}
          <button
            className="btn btn-outline-dark"
            type="submit"
            disabled={state.loading}
          >
            {state.loading ? (
              "..."
            ) : (
              <>
                <FiLogIn /> Sign Up
              </>
            )}
          </button>
          <div className="or font-weight-bold"> OR</div>
          <button
            className="btn btn-outline-secondary"
            onClick={() => {
              props.history.push("/");
            }}
          >
            <FiSend /> Login
          </button>
          <div className="d-flex">
            <div className="social">
              <img src="/images/bg/FACEBOOK.svg" alt="" />
            </div>
            <div className="social">
              <img src="/images/bg/GOOGLE.svg" alt="" />
            </div>
          </div>
        </form>
      </div>

      {/* only the mobile version */}
      <div className="mobile-view">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        <img
          src="/images/bg/BOTTOM_GREY_LAYERS.svg"
          alt=""
          className="bottom_wave"
        />
        <form onSubmit={handleSignUp}>
          <div className="d-flex flex-column justify-content-center align-items-center Login_form">
            {/* Logo */}
            <img src="/images/logo/logo.png" alt="" className="logo_mobile" />

            {/*  */}
            <div className="w-100 inner ">
              <h2 className="header text-left">Let’s Get Start</h2>
              {/* inputs */}
              <div className="input_wrapper pos-relative ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  required
                />
              </div>
              {errors.email && <p className="text-danger">{errors.email}</p>}
              <div className="input_wrapper pos-relative ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={state.phone}
                  onChange={(e) =>
                    setState({ ...state, phone: e.target.value })
                  }
                  required
                />
              </div>
              {errors.phone && <p className="text-danger">{errors.phone}</p>}
              <div className="input_wrapper pos-relative ">
                <input
                  type={`${isPassword ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Password"
                  value={state.password}
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  required
                />
                {isPassword ? (
                  <FiEyeOff
                    className="eye"
                    onClick={() => {
                      setIsPassword(!isPassword);
                    }}
                  />
                ) : (
                  <FiEye
                    className="eye"
                    onClick={() => {
                      setIsPassword(!isPassword);
                    }}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}
              <div className="input_wrapper pos-relative ">
                <input
                  type={`${isPassword2 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Confirm Password"
                  value={state.confirmPassword}
                  onChange={(e) =>
                    setState({ ...state, confirmPassword: e.target.value })
                  }
                  required
                />
                {isPassword ? (
                  <FiEyeOff
                    className="eye"
                    onClick={() => {
                      setIsPassword2(!isPassword2);
                    }}
                  />
                ) : (
                  <FiEye
                    className="eye"
                    onClick={() => {
                      setIsPassword2(!isPassword2);
                    }}
                  />
                )}
              </div>
              {errors.confirmPassword && (
                <p className="text-danger">{errors.confirmPassword}</p>
              )}
              <div className="input-wrapper d-flex mb-4 align-items-center ">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className="mr-3"
                  required
                />
                <span>
                  Agree to our <Link to="/terms"> terms of services</Link>{" "}
                </span>
              </div>
            </div>

            {/* buttons */}
            <button
              className="btn btn-outline-dark"
              type="submit"
              disabled={state.loading}
            >
              {state.loading ? (
                "..."
              ) : (
                <>
                  <FiLogIn /> Sign Up
                </>
              )}
            </button>
            <div className="or font-weight-bold"> OR</div>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                props.history.push("/");
              }}
            >
              <FiSend /> Login
            </button>
            <div className="d-flex">
              <div className="social">
                <img src="/images/bg/FACEBOOK.svg" alt="" />
              </div>
              <div className="social">
                <img src="/images/bg/GOOGLE.svg" alt="" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
