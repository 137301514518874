import axios from 'axios';
import {API_URL} from '../../config/';

function fetchProfile(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}user?user_id=${uid}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateProfile(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}user/update`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const profileService = {
  fetchProfile,
  updateProfile
};
