import {
  PROFILE_LOADING,
  PROFILE_SET_DATA,
} from './constants';
import {profileService} from "./services";
export const setLoading = status => ({
  type: PROFILE_LOADING,
  payload: status
});
export const setData = payload => ({
  type: PROFILE_SET_DATA,
  payload: payload
});
export const fetchProfile = (uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    profileService
      .fetchProfile(uid)
      .then(async (res) => {
        if(res.status)
          await dispatch(setData(res.user));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const updateProfile = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    profileService
    .updateProfile(formData)
    .then(async (res) => {
      dispatch(fetchProfile(formData.user_id))
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
  });
};