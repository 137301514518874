import React, { useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import {
  FiMenu,
  FiHome,
  FiThermometer,
  FiPlus,
  FiActivity,
  FiUser,
} from "react-icons/fi";
import { removeToken, removeRole } from "../../../utils/authManager";
import { connect } from "react-redux";
import { fetchProfile } from "../../../store/profile/actions";
import "./Bottom.scss";

function Bottom(props) {
  var history = useHistory();
  const [open, setOpen] = React.useState(false);

  return (
    <div className="Bottom fixed-bottom">
      <NavLink to="/home" className="Bottom-link">
        <div className="inner">
          <FiHome />
          {/* <div> Home </div> */}
        </div>
      </NavLink>
      <NavLink to="/drug-listing" className="Bottom-link">
        <div className="inner">
          {/* <FiThermometer /> */}
          <img
            src="/images/logo/drug-icon-gray.webp"
            alt=""
            className="drug-icon one"
          />
          <img
            src="/images/logo/drug-icon-active-pink.webp"
            alt=""
            className="drug-icon two"
          />
          {/* <div> Home </div> */}
        </div>
      </NavLink>
      <NavLink to="/create-dose" className="Bottom-link middle ">
        <div className="inner">
          <FiPlus />
          {/* <div> Home </div> */}
        </div>
      </NavLink>
      <NavLink to="/analitics" className="Bottom-link">
        <div className="inner">
          <FiActivity />
          {/* <div> Home </div> */}
        </div>
      </NavLink>
      <NavLink to="/profile" className="Bottom-link">
        <div className="inner">
          <FiUser />
          {/* <div> Home </div> */}
        </div>
      </NavLink>
    </div>
  );
}
const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {
  fetchProfile,
})(Bottom);
