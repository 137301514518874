import React, { useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { FiMenu, FiArrowLeft } from "react-icons/fi";
import { removeToken, removeRole } from "../../../utils/authManager";
import { connect } from "react-redux";
import { fetchProfile } from "../../../store/profile/actions";
import "./InnerHeader.scss";

function InnerHeader(props) {
  var history = useHistory();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={`InnerHeader ${props.desktop ? "" : "fixed-top"}`}>
      <div className="d-flex justify-content-start align-items-center ">
        <div
          className="arrow-back"
          onClick={() => {
            history.goBack();
          }}
        >
          <FiArrowLeft />
        </div>
        <span className="h6 ml-2 font-weight-bold font-color ">
          {props.text}
        </span>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {
  fetchProfile,
})(InnerHeader);
