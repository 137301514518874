import {
  DOSES_LOADING,
  DOSES_SET_DATA,
  DOSES_SET_DETAIL,
  DOSES_SET_DOSE,
  DOSES_RESET_DOSE,
  DOSES_SET_SCHEDULE_DOSE,
  DOSES_RESET_SCHEDULE_DOSE,
  DOSES_SET_SCHEDULES,
  DOSES_SET_NOTIFICATION,
  DOSES_SET_SHOW_NOTIFICATION,
  DOSES_SET_ANALYTICAL_DATA,
} from "./constants";
import { doseService } from "./services";
import { drugService } from "../drugs/services";
import moment from "moment";

export const setLoading = (status) => ({
  type: DOSES_LOADING,
  payload: status,
});
export const setData = (payload) => ({
  type: DOSES_SET_DATA,
  payload: payload,
});
export const setDetail = (payload) => ({
  type: DOSES_SET_DETAIL,
  payload: payload,
});
export const setDose = (payload) => ({
  type: DOSES_SET_DOSE,
  payload: payload,
});
export const resetDose = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch({
      type: DOSES_RESET_DOSE,
    });
    resolve();
  });
};
export const setScheduleDose = (payload) => ({
  type: DOSES_SET_SCHEDULE_DOSE,
  payload: payload,
});
export const resetScheduleDose = () => ({
  type: DOSES_RESET_SCHEDULE_DOSE,
});
export const setSchedules = (payload) => ({
  type: DOSES_SET_SCHEDULES,
  payload: payload,
});
export const setNotification = (payload) => ({
  type: DOSES_SET_NOTIFICATION,
  payload: payload,
});
export const setShowNotification = (payload) => ({
  type: DOSES_SET_SHOW_NOTIFICATION,
  payload: payload,
});
export const setAnalyticalData = (payload) => ({
  type: DOSES_SET_ANALYTICAL_DATA,
  payload: payload,
});
export const fetchDoses = (uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .fetchDoses(uid)
      .then(async (res) => {
        await dispatch(setData(res.doses));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const fetchDoseDetails = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .fetchDoseDetails(id)
      .then(async (res) => {
        let doseObj = {
          dose_type: res.dose.dose_type,
          substance_type: res.dose.substance_type,
          amount_of_dose: res.dose.amount_of_dose.split(" ")[0],
          unit_of_dose: res.dose.amount_of_dose.split(" ")[1],
          emotions: JSON.parse(res.dose.emotions),
          mood_before_dose: res.dose.mood_before_dose,
          comments: res.dose.comments,
          dose_date: Date.now(res.dose.dose_date),
          dose_time: Date.now(res.dose.dose_time),
          id: res.dose.id,
        };
        dispatch(setDetail(doseObj));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const createDose = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .createDose(formData)
      .then(async (res) => {
        //   await dispatch(resetDose());
        dispatch(fetchDoseNowNotification(formData.user_id));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const createScheduleDose = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .createScheduleDose(formData)
      .then(async (res) => {
        await dispatch(resetScheduleDose());
        dispatch(fetchSchedules(formData.user_id));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const fetchSchedules = (uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .fetchSchedules(uid)
      .then(async (res) => {
        await dispatch(setSchedules(res.schedules));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const fetchScheduleDetails = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
    .fetchScheduleDetails(id)
    .then(async (res) => {
      await dispatch(setScheduleDose({
        ...res.schedules,
        substance_type: res.schedules.substance_type,
        amount_of_dose: res.schedules.amount_of_dose,
        unit_of_dose: res.schedules.unit_of_dose,
        number_of_dose: res.schedules.number_of_dose,
        start_date: moment(res.schedules.start_date).format("yyyy-MM-DD"),
        end_date: moment(res.schedules.end_date).format("yyyy-MM-DD"),
        days_on: res.schedules.days_on,
        days_off: res.schedules.days_off,
        frequency: res.schedules.frequency,
        alarm: res.schedules.alarm == 1 ? true : false,
        reminder: res.schedules.reminder == 1 ? true : false,
        time: res.schedules.time
          ? moment(res.schedules.time, "h:mm:a").toDate()
          : moment().toDate(),
        id: res.schedules.id,
        event_ids: JSON.parse(res.schedules.event_ids),
      }));
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
  });
};
export const changeStatus = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    doseService
      .changeStatus(formData)
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteDose = (id, uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    doseService
      .deleteDose(id)
      .then(async (res) => {
        dispatch(fetchDoses(uid));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateDose = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .updateDose(formData)
      .then(async (res) => {
        await dispatch(fetchDoses(formData.user_id));
        await dispatch(resetDose());
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const createPastRecord = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .createPastRecord(formData)
      .then(async (res) => {
        await dispatch(resetDose());
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const fetchAnalyticalData = (uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    doseService
      .fetchAnalyticalData(uid)
      .then(async (res) => {
        await dispatch(setAnalyticalData(res));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const fetchDoseNowNotification = (uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    doseService
      .fetchCurrentDose(uid)
      .then(async (res) => {
        if (res.dose.length) {
          let substance = res.dose[0].substance_type;
          drugService.fetchDrugDetail(substance).then(async (resp) => {
            let drugDetail = resp.data.substances[0];
            if (drugDetail.roas) {
              if (drugDetail.roas.length > 0) {
                let duration = drugDetail.roas[0].duration
                  ? drugDetail.roas[0].duration
                  : {};
                let createdOn = res.dose[0].created_on;
                if (
                  !duration.onset &&
                  !duration.afterglow &&
                  !duration.duration &&
                  !duration.peak
                ) {
                  if (duration.total) {
                    let maxTime = moment(createdOn).add(
                      duration.total.max,
                      duration.total.units
                    );
                    if (moment() <= maxTime) {
                      await dispatch(
                        setNotification({
                          title: "Duration",
                          description:
                            "Your dose duration period has been started, would you like to record your thoughts?",
                          duration: duration.total.max - duration.total.min,
                          units: duration.total.units,
                          doseId: res.dose[0].id,
                          durationEvent: "peak",
                        })
                      );
                      await dispatch(setShowNotification(true));
                      return;
                    } else {
                      await dispatch(setShowNotification(false));
                    }
                  }
                }
                if (duration.onset) {
                  let minTime = moment(createdOn).add(
                    duration.onset.min,
                    duration.onset.units
                  );
                  let maxTime = moment(createdOn).add(
                    duration.onset.max,
                    duration.onset.units
                  );
                  if (moment() <= maxTime) {
                    await dispatch(
                      setNotification({
                        title: "come up",
                        description:
                          "Your coming to the peak of your trip, would you like to record your thoughts?",
                        duration: duration.onset.max - duration.onset.min,
                        units: duration.onset.units,
                        doseId: res.dose[0].id,
                        durationEvent: "come_up",
                      })
                    );
                    await dispatch(setShowNotification(true));
                    return;
                  } else {
                    await dispatch(setShowNotification(false));
                  }
                }
                if (duration.duration || duration.peak) {
                  if (duration.duration) {
                    let minTime = moment(createdOn).add(
                      duration.duration.min,
                      duration.duration.units
                    );
                    let maxTime = moment(createdOn).add(
                      duration.duration.max,
                      duration.duration.units
                    );
                    if (moment() >= minTime && moment() <= maxTime) {
                      await dispatch(
                        setNotification({
                          title: "peak",
                          description:
                            "Your coming to the peak of your trip, would you like to record your thoughts?",
                          duration:
                            duration.duration.max - duration.duration.min,
                          units: duration.duration.units,
                          doseId: res.dose[0].id,
                          durationEvent: "peak",
                        })
                      );
                      await dispatch(setShowNotification(true));
                      return;
                    } else {
                      await dispatch(setShowNotification(false));
                    }
                  } else {
                    let minTime = moment(createdOn).add(
                      duration.peak.min,
                      duration.peak.units
                    );
                    let maxTime = moment(createdOn).add(
                      duration.peak.max,
                      duration.peak.units
                    );
                    if (moment() >= minTime && moment() <= maxTime) {
                      await dispatch(
                        setNotification({
                          title: "peak",
                          description:
                            "Your coming to the peak of your trip, would you like to record your thoughts?",
                          duration: duration.peak.max - duration.peak.min,
                          units: duration.peak.units,
                          doseId: res.dose[0].id,
                          durationEvent: "peak",
                        })
                      );
                      await dispatch(setShowNotification(true));
                      return;
                    } else {
                      await dispatch(setShowNotification(false));
                    }
                  }
                }
                if (duration.afterglow) {
                  let minTime = moment(createdOn).add(
                    duration.afterglow.min,
                    duration.afterglow.units
                  );
                  let maxTime = moment(createdOn).add(
                    duration.afterglow.max,
                    duration.afterglow.units
                  );
                  if (moment() >= minTime && moment() <= maxTime) {
                    await dispatch(
                      setNotification({
                        title: "come down",
                        description:
                          "You've finished your journey, tell us about it",
                        duration:
                          duration.afterglow.max - duration.afterglow.min,
                        units: duration.afterglow.units,
                        doseId: res.dose[0].id,
                        durationEvent: "come_down",
                      })
                    );
                    await dispatch(setShowNotification(true));
                  } else {
                    await dispatch(setShowNotification(false));
                  }
                }
                resolve(true);
              }
            }
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteScheduleDose = (id, uid) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    doseService
      .deleteScheduleDose(id)
      .then(async (res) => {
        dispatch(fetchSchedules(uid));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
