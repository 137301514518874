import React from "react";
import "./FlatAdds.scss";

import {connect} from 'react-redux';
import {incrementClick} from '../../../store/ads/actions';
import {UPLOAD_URL} from '../../../config/';

const FlatAdds = (props) => {
  const handleClickAd = () => {
    if (props.ad.id) {
      props.incrementClick(props.ad.id);
      if (props.ad.link){
        window.open((props.ad.link?.indexOf('://') === -1) ? 'http://' + props.ad.link : props.ad.link, '_blank');
      }
    }
  };
  return (
    <div className="FlatAdds" style={{ marginBottom: props.noMB ? 0 : 50 }} onClick={()=>{
      handleClickAd()
    }}>
      <div className='pos-relative' >
        <h4>Ad:</h4>
        <img
          src={`${UPLOAD_URL}${props.ad?.landscape_media}`}
          alt=""
        />
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    ad: state.ads.ad,
  };
}
export default connect(mapStateToProps, {
  incrementClick,
})(FlatAdds);