import React from "react";
import "./HistoryList.scss";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";

import { connect } from "react-redux";
import { deleteDose } from "../../store/doses/actions";
import { getToken } from "../../utils/authManager";
import {useHistory} from "react-router-dom";
import Swal from "sweetalert2";

const HistoryList = (props) => {
  const history = useHistory();
  const [show, setShow] = React.useState(true);
  const renderMoodBeforeDose = () => {
    switch (parseInt(props.item.mood_before_dose)) {
      case 1:
        return "Feeling Worst";
      case 2:
        return "Feeling Down";
      case 3:
        return "Feeling Normal";
      case 4:
        return "Feeling Better";
      case 5:
        return "Feeling Best";
      default:
        return "N/A";
    }
  };
  const handleDeleteDose = async () => {
    Swal.fire({
      title: "Are you sure want to delete this dose ?",
      showCancelButton: true,
      confirmButtonText: `OK`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const userId = await getToken();
        props.deleteDose(props.item.id, userId);
      }
    });
  };
  return (
    <div className="HistoryList">
      <div
        className="d-flex justify-content-between align-items-center p-2 desk-bg "
        onClick={() => {
          setShow(!show);
        }}
      >
        <h6 className="m-0">Date Dosed</h6>
        <div className="d-flex justify-content-end align-items-center">
          <h6 className="m-0">{props.item.dose_date}</h6>
          {show ? (
            <FiArrowUpCircle className="ml-2" />
          ) : (
            <FiArrowDownCircle className="ml-2" />
          )}
        </div>
      </div>
      {show && (
        <>
          <div className="desktop-only">
            <div className="details p-2">
              <div className="row">
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Time</h6>
                    <h6 className="right">{props.item.dose_time}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Type</h6>
                    <h6 className="right">{props.item.dose_type}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Amount of Dose</h6>
                    <h6 className="right">{props.item.amount_of_dose}</h6>
                  </div>
                </div>
                <div className="col-6">
                  <div className="item">
                    <h6 className="left">Mood Before</h6>
                    <h6 className="right">{renderMoodBeforeDose()}</h6>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item column">
                    <h6 className="left">Comments</h6>
                    <h6 className="right">{props.item.comments}</h6>
                  </div>
                </div>
              </div>

              <div className="item">
                <button
                  className="btn btn-outline-light w-50"
                  onClick={() => handleDeleteDose()}
                >
                  Delete
                </button>
                <button className="btn btn-light w-50" onClick={()=>{
                  history.push(`/dosenow/step-1/${props.item.id}`)
                }}>Edit</button>
              </div>
            </div>
          </div>
          <div className="mobile-only">
            <div className="details p-2">
              <div className="item">
                <h6 className="left">Time</h6>
                <h6 className="right">{props.item.dose_time}</h6>
              </div>
              <div className="item">
                <h6 className="left">Type</h6>
                <h6 className="right">{props.item.dose_type}</h6>
              </div>
              <div className="item">
                <h6 className="left">Amount of Dose</h6>
                <h6 className="right">{props.item.amount_of_dose}</h6>
              </div>
              <div className="item">
                <h6 className="left">Mood Before</h6>
                <h6 className="right">{renderMoodBeforeDose()}</h6>
              </div>
              <div className="item column">
                <h6 className="left">Comments</h6>
                <h6 className="right">{props.item.comments}</h6>
              </div>
              <div className="item">
                <button
                  className="btn btn-outline-light w-50"
                  onClick={() => handleDeleteDose()}
                >
                  Delete
                </button>
                <button className="btn btn-light w-50" onClick={()=>{
                  history.push(`/dosenow/step-1/${props.item.id}`)
                }}>Edit</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, {
  deleteDose,
})(HistoryList);
