import React, { useEffect, useState } from "react";
import "./ChangePassword.scss";
import { Link } from "react-router-dom";
import { FiCheck } from "react-icons/fi";

// component
import InnerHeader from "../../components/template/innerHeader/InnerHeader";

import { connect } from "react-redux";
import { fetchProfile, updateProfile } from "../../store/profile/actions";
import firebase from "../../firebase/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = (props) => {
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [loading, setLoading] = useState(false);
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  

  const reauthenticate = () => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      state.password
    );
    return user.reauthenticateWithCredential(cred);
  };
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    
  };
  return (
    <div className="ChangePassword">
      {/* only the mobile version */}
      <div className="desktop-view">
        {/* Header */}
        <InnerHeader text="Change Password" desktop={true} />
        <div className="mobile-wrapper">
          <form onSubmit={handleUpdateProfile}>
            <div className="inner-form">
              <div className="row">
              <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Old password"
                      onChange={(e) =>
                        setState({ ...state, oldPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="New password"
                      required
                      onChange={(e) =>
                        setState({ ...state, newPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Confirm new password"
                      onChange={(e) =>
                        setState({ ...state, confirmPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                
                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    <FiCheck /> Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* Header */}
        <InnerHeader text="Edit Profile" />
        <div className="mobile-wrapper">
          <form onSubmit={handleUpdateProfile}>
            <div className="inner-form">
              <div className="row">
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Old password"
                      required
                      onChange={(e) =>
                        setState({ ...state, oldPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="New password"
                      onChange={(e) =>
                        setState({ ...state, newPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Confirm new password"
                      value={state.lastName}
                      onChange={(e) =>
                        setState({ ...state, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    <FiCheck /> Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
  };
}

export default connect(mapStateToProps, {
  fetchProfile,
  updateProfile,
})(ChangePassword);
