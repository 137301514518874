import React, { useEffect } from "react";
import "./Step1.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import $ from "jquery";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import Loader from "../../../components/loader/Loader";

import { connect } from "react-redux";
import {
  setDose,
  resetDose,
  fetchDoseDetails,
} from "../../../store/doses/actions";
import { fetchDrugs } from "../../../store/drugs/actions";

const DoseNowStep1 = (props) => {
  useEffect(() => {
    if (props?.match?.params?.id) {
      const { id } = props.match.params;
      props.fetchDoseDetails(id);
    } else {
      props.resetDose();
    }

    $("#adModal").show();
  }, []);
  useEffect(() => {
    if (props.drugs.length === 0) {
      props.fetchDrugs("Psychedelics");
    }
  }, [props.drugs]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props?.match?.params?.id) {
      props.history.push(`/dosenow/step-2/${props?.match?.params?.id}`);
    } else {
      props.history.push(`/dosenow/step-2`);
    }
  };
  return (
    <div className="doseNowStep1">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* bg waves */}
        {/* <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" /> */}
        {/* haeder */}
        <InnerHeader text="Dose Now" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 1: Fill the details in the below form</h1>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.dose_type}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_type: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="" disabled selected>
                      Type of dose
                    </option>
                    <option value="macro">Macro</option>
                    <option value="micro">Micro</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.dose.substance_type}
                    required
                  >
                    <option value="" disabled selected>
                      Choose substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-10">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={props.dose.amount_of_dose}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.unit_of_dose}
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        unit_of_dose: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      Kg
                    </option>
                    <option value="g">g</option>
                    <option value="μg">μg</option>
                    <option value="mg">mg</option>
                    <option value="tabs">tabs</option>
                  </select>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>
      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose Now" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 1: Fill the details in the below form</h1>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.dose_type}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        dose_type: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="" disabled selected>
                      Type of dose
                    </option>
                    <option value="macro">Macro</option>
                    <option value="micro">Micro</option>
                  </select>
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        substance_type: e.target.value,
                      });
                    }}
                    value={props.dose.substance_type}
                    required
                  >
                    <option value="" disabled selected>
                      Choose substance
                    </option>
                    {props.drugs.map((drug, i) => (
                      <option value={drug.name} key={i}>
                        {drug.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-8">
                <div className="input_wrapper pos-relative ">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={props.dose.amount_of_dose}
                    onChange={(e) =>
                      props.setDose({
                        ...props.dose,
                        amount_of_dose: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input_wrapper pos-relative ">
                  <select
                    className="form-control"
                    value={props.dose.unit_of_dose}
                    onChange={(e) => {
                      props.setDose({
                        ...props.dose,
                        unit_of_dose: e.target.value,
                      });
                    }}
                  >
                    <option value="" selected>
                      Kg
                    </option>
                    <option value="g">g</option>
                    <option value="μg">μg</option>
                    <option value="mg">mg</option>
                    <option value="tabs">tabs</option>
                  </select>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
    drugs: state.drugs.drugs,
    loading: state.drugs.loading,
  };
}
export default connect(mapStateToProps, {
  setDose,
  fetchDrugs,
  resetDose,
  fetchDoseDetails,
})(DoseNowStep1);
