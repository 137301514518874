import React from "react";
import "./SaferUsage.scss";
import { Link } from "react-router-dom";
import { FiCheck, FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const SaferUsage = () => {
  const [open, setOpen] = React.useState(true);

  const data = [
    {
      img: "/images/safer/usage/1.png",
      text: "Use drug testing services if possible.",
    },
    {
      img: "/images/safer/usage/2.png",
      text:
        "Drink alcohol-free drinks and take a break in the fresh air from time to time",
    },
    {
      img: "/images/safer/usage/3.png",
      text:
        "Watch each other and talk to your friends if your'e feeling unwell ",
    },

    {
      img: "/images/safer/usage/4.png",
      text:
        "Driving under the influence of psychoactive substances is dangerous",
    },
  ];

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Safer Usage</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom">
            {data.map((item, i) => (
              <div className="SaferUsage" key={i}>
                <img src={item.img} alt="" />
                <h6>{item.text}</h6>
              </div>
            ))}
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SaferUsage;
