import axios from 'axios';
function fetchDrugs(psychoactiveClass) {
  return new Promise(async (resolve, reject) => {
    axios
    .post(
    'https://api.psychonautwiki.org/',
    {
        query: `{
        substances(limit: 100, offset: 0, psychoactiveClass: "${psychoactiveClass}") {
            name
            featured
            summary
            images {
                image
            }
            # subjective effects
            effects {
                name url
            }
          }
        }`,
    },
    {
      headers: {
      'Content-Type': 'application/json',
      },
    },
    )
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
        reject(err);
    });
  });
}
function fetchDrugDetail(name) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        'https://api.psychonautwiki.org/',
        {
          query: `{
          substances(query: "${name}") {
                  name
                      featured
                      summary
                      toxicity
                      crossTolerances
                  addictionPotential
                  dangerousInteractions {
                      name
                  }
                      unsafeInteractions {
                      name
                  }
                      uncertainInteractions {
                      name
                      toxicity
                      addictionPotential
                  }
                      tolerance{
                      full
                      half
                      zero
                  }
                      class {
                      chemical
                      psychoactive
                  }
                  images {
                      thumb
                      image
                  }
                  # routes of administration
                  roas {
                      name
          
                      dose {
                          units
                          threshold
                          heavy
                          common { min max }
                          light { min max }
                          strong { min max }
                      }
          
                      duration {
                          afterglow { min max units }
                          comeup { min max units }
                          duration { min max units }
                          offset { min max units }
                          onset { min max units }
                          peak { min max units }
                          total { min max units }
                      }
          
                      bioavailability {
                          min max
                      }
                  }
          
                  # subjective effects
                  effects {
                      name url
                  }
              }
          }`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const drugService = {
  fetchDrugs,
  fetchDrugDetail,
};
