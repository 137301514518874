import {
  GOALS_LOADING,
  GOALS_SET_DATA,
  GOALS_SET_ACTIVE_GOAL,
  GOALS_SET_EXISTING_ACTIVE_GOAL,
  GOALS_SET_GOAL_HISTORY,
} from "./constants";

const initialState = {
  loading: false,
  goals: [],
  activeGoal: "",
  existingActiveGoal: [],
  goalHistory: [],
};

export function goalReducer(state = initialState, action) {
  switch (action.type) {
    case GOALS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GOALS_SET_DATA:
      return {
        ...state,
        goals: action.payload,
      };
    case GOALS_SET_ACTIVE_GOAL:
      return {
        ...state,
        activeGoal: action.payload,
      };
    case GOALS_SET_EXISTING_ACTIVE_GOAL:
      return {
        ...state,
        existingActiveGoal: action.payload,
      };
    case GOALS_SET_GOAL_HISTORY:
      return {
        ...state,
        goalHistory: action.payload,
      };
    default:
      return state;
  }
}
