import React from "react";
import { Link } from "react-router-dom";
import "./ImageCard.scss";

const ImageCard = (props) => {
  const getImage = () => {
    if(props.item.name === "Cannabis"){
      return "https://psychonautwiki.org/w/images/7/76/Cannabis_(Botany).png";
    }
    else if(props.item.name === "Psilocybin mushrooms"){
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Cubensis.natural.habitat-2.jpg/300px-Cubensis.natural.habitat-2.jpg";
    }
    else if (props.item.images) {
      for (let image of props.item.images) {
        var result = /[^/]*$/.exec(image.image)[0];
        if (result.includes(props.item.name)) {
          return image.image;
        }
      }
    }
  };
  return (
    <Link to={`/drug-detail/${props.item.name}`}>
    <div className="ImageCard">
      <img src={getImage()} alt="" />
      <h6>{props.item.name}</h6>
    </div>
    </Link>
  );
};

export default ImageCard;
