import {ADS_LOADING, ADS_SET_DATA, ADS_SET_ADS} from './constants';
import {adsService} from './services';

export const setLoading = (status) => ({
  type: ADS_LOADING,
  payload: status,
});
export const setData = (payload) => ({
  type: ADS_SET_DATA,
  payload: payload,
});
export const setAds = (payload) => ({
  type: ADS_SET_ADS,
  payload: payload,
});
export const incrementImpressions = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    adsService
    .incrementImpressions(formData)
    .then(async (res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const fetchAds = () => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    adsService
      .fetchAds()
      .then(async (res) => {
        if (res.status) {
          if(res.ad.length > 0){
            const adsState = await getState().ads;
            const adsExists = adsState.ads.filter(ad => ad.id == res.ad[0].id).length;
            if(adsExists === 0){
            // const ipAddress = await NetworkInfo.getIPAddress();
            const ipAddress = "0.0.0.0";
            dispatch(incrementImpressions({
              device_ip: ipAddress,
              ad_id: res.ad[0].id
            }));
            adsState.ads.push(res.ad[0]);
            dispatch(setAds(adsState.ads))
          }
          }
          dispatch(setData(res.ad));
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  });
};
export const incrementClick = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    adsService
    .incrementClick(id)
    .then(async (res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
  });
};
