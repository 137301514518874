import React, { useEffect } from "react";
import "./Step3.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";

import { connect } from "react-redux";
import { setDose } from "../../../store/doses/actions";

const RecordPastTripStep3 = (props) => {
  const Options = [
    {
      name: "Happy",
      image: "/images/emojies/happy.png",
    },
    {
      name: "Angry",
      image: "/images/emojies/angry.png",
    },
    {
      name: "Sad",
      image: "/images/emojies/sad.png",
    },
    {
      name: "Loved",
      image: "/images/emojies/loved.png",
    },
    {
      name: "Calm",
      image: "/images/emojies/calm.png",
    },
    {
      name: "Anxiety",
      image: "/images/emojies/anxiety.png",
    },
    {
      name: "Confused",
      image: "/images/emojies/confused.png",
    },
    {
      name: "Bored",
      image: "/images/emojies/bored.png",
    },
    {
      name: "Scared",
      image: "/images/emojies/scared.png",
    },
  ];
  const handleAddComment = (comment, index) => {
    let moods = props.dose.emotions;
    moods[index]["comment"] = comment;
    props.setDose({ ...props.dose, emotions: [...moods] });
  };
  useEffect(() => {
    if (!props.dose?.substance_type) {
      props.history.push("/record-past-trip/step-1");
    }
  }, [props.dose]);
  return (
    <div className="doseNowStep3">
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Record Past Trip" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 3: Write somethings about those emotions
          </h1>
          <div className="EmotionsCommentWrapper my-3">
            {props.dose.emotions.map((item, i) => (
              <div className="EmotionsCommentItem">
                <div className="Emotions">
                  <img
                    src={Options.filter((op) => op.name === item.mood)[0].image}
                    alt=""
                  />
                  <h6>{item.mood}</h6>
                </div>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Add something related ..."
                  className="EmotionsComment"
                  value={item.comment}
                  onChange={(e) => handleAddComment(e.target.value, i)}
                ></textarea>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Link to="/record-past-trip/step-4">
                <button className="btn btn-outline-dark">
                  Next <FiArrowRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose Now" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 3: Write somethings about those emotions
          </h1>
          <div className="EmotionsCommentWrapper my-3">
            {props.dose.emotions.map((item, i) => (
              <div className="EmotionsCommentItem">
                <div className="Emotions">
                  <img
                    src={Options.filter((op) => op.name === item.mood)[0].image}
                    alt=""
                  />
                  <h6>{item.mood}</h6>
                </div>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Add something related ..."
                  className="EmotionsComment"
                  value={item.comment}
                  onChange={(e) => handleAddComment(e.target.value, i)}
                ></textarea>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Link to="/record-past-trip/step-4">
                <button className="btn btn-outline-dark">
                  Next <FiArrowRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
  };
}

export default connect(mapStateToProps, {
  setDose,
})(RecordPastTripStep3);
