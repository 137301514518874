import React, { useEffect, useState } from "react";
import "./EditProfile.scss";
import { Link } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import moment from "moment";

// component
import InnerHeader from "../../components/template/innerHeader/InnerHeader";

import { connect } from "react-redux";
import { fetchProfile, updateProfile } from "../../store/profile/actions";
import { getToken, setToken } from "../../utils/authManager";
import firebase from "../../firebase/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditProfile = (props) => {
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    dob: "",
    bio: "",
    password: "",
    error: {},
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const loadData = async () => {
    const userId = await getToken();
    props.fetchProfile(userId);
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (props.profile.hasOwnProperty("user_id")) {
      setState({
        ...state,
        email: props.profile.email,
        phone: props.profile.phone || "",
        dob: props.profile.dob ? moment(props.profile.dob).format("yyyy-MM-DD") : "",
        bio: props.profile.bio || "",
        firstName: props.profile.name ? props.profile.name.split(" ")[0] : "",
        lastName: props.profile.name ? props.profile.name.split(" ")[1] : "",
      });
    }
  }, [props.profile]);

  const reauthenticate = () => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      state.password
    );
    return user.reauthenticateWithCredential(cred);
  };
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { user_id, email } = props.profile;
      var user = firebase.auth().currentUser;
      // console.log(props.user.email, state.email);
      if (email !== state.email) {
        if (state.password) {
          if (user) {
            await reauthenticate();
            await user.updateEmail(state.email.trim());
          }
        } else {
          var password = prompt("Enter your password");
          setState({ ...state, password });
          // handleUpdateProfile();
          return;
        }
      }
      const resp = await props.updateProfile({
        id: props.profile.id,
        user_id,
        name: `${state.firstName} ${state.lastName}`,
        email: state.email,
        phone: state.phone,
        dob: state.dob,
        bio: state.bio,
      });
      notifySuccess("Profile updated successfully!");
      setTimeout(() => {
        props.history.goBack();
      }, 2000);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        notifyError("The email address is already in use by another account.");
      } else if (err.hasOwnProperty("response")) {
        const { message } = err.response.data;
        notifyError(message);
      } else {
        notifyError("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="EditProfile">
      {/* only the mobile version */}
      <div className="desktop-view">
        {/* Header */}
        <InnerHeader text="Edit Profile" desktop={true} />
        <div className="mobile-wrapper">
          <form onSubmit={handleUpdateProfile}>
            <div className="inner-form">
              <div className="row">
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={state.email}
                      required
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={state.firstName}
                      onChange={(e) =>
                        setState({ ...state, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={state.lastName}
                      onChange={(e) =>
                        setState({ ...state, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      value={state.phone}
                      required
                      onChange={(e) =>
                        setState({ ...state, phone: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Date of birth"
                      value={state.dob}
                      onChange={(e) =>
                        setState({ ...state, dob: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bio"
                      value={state.bio}
                      onChange={(e) =>
                        setState({ ...state, bio: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    <FiCheck /> Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        {/* <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" /> */}
        {/* Header */}
        <InnerHeader text="Edit Profile" />
        <div className="mobile-wrapper">
          <form onSubmit={handleUpdateProfile}>
            <div className="inner-form">
              <div className="row">
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={state.email}
                      required
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={state.firstName}
                      onChange={(e) =>
                        setState({ ...state, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={state.lastName}
                      onChange={(e) =>
                        setState({ ...state, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      value={state.phone}
                      required
                      onChange={(e) =>
                        setState({ ...state, phone: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Date of birth"
                      value={state.dob}
                      onChange={(e) =>
                        setState({ ...state, dob: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input_wrapper pos-relative ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bio"
                      value={state.bio}
                      onChange={(e) =>
                        setState({ ...state, bio: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    <FiCheck /> Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
  };
}

export default connect(mapStateToProps, {
  fetchProfile,
  updateProfile,
})(EditProfile);
