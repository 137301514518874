import React, { useState, useEffect } from "react";
import "./Step3.scss";
import { Link } from "react-router-dom";
import { FiRefreshCw, FiCheck } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import GoalRating from "../../../components/goal/goalRating/GoalRating";

import { connect } from "react-redux";
import {
  dailyUpdateGoal,
  fetchGoalHistory,
} from "../../../store/goals/actions";
import moment from "moment";
import { getToken } from "../../../utils/authManager";
import { ToastContainer, toast } from "react-toastify";

const GoalStep3 = (props) => {
  const notifySuccess = (message) => toast.success(message);
  const [state, setState] = useState({
    rating: "",
    loading: false,
    btnLoading: false,
  });

  const mood = [
    {
      name: "5. Feeling Best",
      value: 5,
      img: "/images/emotions/happy.png",
      activeImg: "/images/emotions/happyColor.png",
    },
    {
      name: "4. Feeling Better",
      value: 4,
      img: "/images/emotions/smle.png",
      activeImg: "/images/emotions/smleColor.png",
    },
    {
      name: "3. Feeling Normal",
      value: 3,
      img: "/images/emotions/bleh.png",
      activeImg: "/images/emotions/blehColor.png",
    },
    {
      name: "2. Feeling Down",
      value: 2,
      img: "/images/emotions/down.png",
      activeImg: "/images/emotions/downColor.png",
    },
    {
      name: "1. Feeling Worst",
      value: 1,
      img: "/images/emotions/sad.png",
      activeImg: "/images/emotions/sadColor.png",
    },
  ];
  const loadData = async () => {
    const userId = await getToken();
    props.fetchGoalHistory(userId);
  };
  const handleUpdateGoalStatus = async () => {
    setState({ ...state, btnLoading: true });
    const userId = await getToken();
    await props.dailyUpdateGoal({
      goal_id: props.match?.params?.id,
      user_id: userId,
      mood_rating: state.rating,
    });
    setState({ ...state, btnLoading: false });
    notifySuccess("Mood rating updated successfully");
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (props.goalHistory.length) {
      props.goalHistory.map((goal) => {
        if (
          moment(goal.created_on, "YYYY-MM-DD").format("DD") ===
          moment().format("DD")
        ) {
          setState({ ...state, rating: parseInt(goal.mood_rating) });
        }
      });
    } else {
      setState({ ...state, rating: "" });
    }
  }, [props.goalHistory]);
  return (
    <div className="GoalStep3">
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Record Past Trip" desktop={true} />
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            How would you rate todays happiness out of 1 to 5 rating
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <GoalRating
            goal={mood}
            rating={state.rating}
            onChange={(value) => setState({ ...state, rating: value })}
          />
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark pos-abs"
                onClick={() => props.history.push("/goal/step-1")}
              >
                <FiRefreshCw /> Change Goal
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => handleUpdateGoalStatus()}
                disabled={state.btnLoading}
              >
                {state.btnLoading ? (
                  "..."
                ) : (
                  <>
                    <FiCheck /> Update
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Goal" />

        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            How would you rate todays happiness out of 1 to 5 rating
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <GoalRating
            goal={mood}
            rating={state.rating}
            onChange={(value) => setState({ ...state, rating: value })}
          />
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark pos-abs"
                onClick={() => props.history.push("/goal/step-1")}
              >
                <FiRefreshCw /> Change Goal
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => handleUpdateGoalStatus()}
                disabled={state.btnLoading}
              >
                {state.btnLoading ? (
                  "..."
                ) : (
                  <>
                    <FiCheck /> Update
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    goalHistory: state.goals.goalHistory,
  };
}

export default connect(mapStateToProps, {
  dailyUpdateGoal,
  fetchGoalHistory,
})(GoalStep3);
