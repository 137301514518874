import React from "react";
import "./SaferPsychedelics.scss";
import { Link } from "react-router-dom";
import { FiCheck, FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const SaferPsychedelics = () => {
  const [open, setOpen] = React.useState(true);

  const data = [
    {
      img: "/images/safer/psychedelic/1.png",
      text:
        "Set: take psychedelics with a comfortable mindset in which you feel good mentally and physically",
    },
    {
      img: "/images/safer/psychedelic/2.png",
      text:
        "Setting: take psychedelics in a physically and social enviroment in which you feel comfortable and only together with experienced friends and not alone",
    },
    {
      img: "/images/safer/psychedelic/3.png",
      text:
        "Prepare: eat only light and not less than 2 hours before consumption",
    },

    {
      img: "/images/safer/psychedelic/4.png",
      text:
        "Dose: take it slow and don't redose if it doesn't work right away ",
    },
    {
      img: "/images/safer/psychedelic/5.png",
      text:
        "Rest: treat yourself with rest and relaxation the day afterand process the experience",
    },
    {
      img: "/images/safer/psychedelic/6.png",
      text:
        "Reflect: psychedelic experience may have influence that extends for weeks, or even months into the future",
    },
  ];

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Safer Psychedelics</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom">
            {data.map((item, i) => (
              <div className="saferPsychedelics" key={i}>
                <img src={item.img} alt="" />
                <h6>{item.text}</h6>
              </div>
            ))}
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SaferPsychedelics;
