import React, { useEffect, useState } from "react";
import "./DrugDetail.scss";

// component
import InnerHeader from "../../components/template/innerHeader/InnerHeader";
import HarmPotential from "../../components/drugDetail/harmPotential/HarmPotential";
import Effects from "../../components/drugDetail/effects/Effects";
import Tolerance from "../../components/drugDetail/tolerance/Tolerance";
import SaferPsychedelics from "../../components/drugDetail/saferPsychedelics/SaferPsychedelics";
import SaferUsage from "../../components/drugDetail/saferUsage/SaferUsage";
import Duration from "../../components/drugDetail/duration/Duration";
import Dosage from "../../components/drugDetail/dosage/Dosage";
import Loader from "../../components/loader/Loader";
import FlatAdds from "../../components/adds/flat/FlatAdds";

import { connect } from "react-redux";
import { fetchDrugDetail } from "../../store/drugs/actions";
import { fetchAds } from "../../store/ads/actions";

const DrugDetail = (props) => {
  const [drug, setDrug] = useState({});
  const types = [
    { name: "common" },
    { name: "psychedelic" },
    { name: "research-chemical" },
  ];
  useEffect(() => {
    if (props.match?.params?.name) {
      props.fetchDrugDetail(props.match?.params?.name);
    }
  }, []);
  useEffect(() => {
    if (props.drug.hasOwnProperty("name")) {
      setDrug(props.drug);
    }
  }, [props.drug]);
  const getImage = () => {
    if (drug.name === "Cannabis") {
      return "https://psychonautwiki.org/w/images/7/76/Cannabis_(Botany).png";
    } else if (drug.name === "Psilocybin mushrooms") {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Cubensis.natural.habitat-2.jpg/300px-Cubensis.natural.habitat-2.jpg";
    } else if (drug.images) {
      for (let image of drug.images) {
        var result = /[^/]*$/.exec(image.image)[0];
        if (result.includes(drug.name)) {
          return image.image;
        }
      }
    }
  };
  useEffect(() => {
    props.fetchAds();
    const interval = setInterval(() => {
      props.fetchAds();
    }, 80000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="DrugDetail">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        <InnerHeader text="" desktop={true} />
        <div className="header-img">
          <img src={getImage()} alt="" className="drugimg" />
        </div>
        <div className="row">
          <div className="col-6">
            <div className="header-info section">
              {/* <img src="/images/bg/drug-detail.png" alt="" className="img-abs" /> */}
              <div className="above">
                <h1 className="name">{drug.name}</h1>
                <h6>( {drug.name} )</h6>
                {drug.class && (
                  <>
                    {drug.class.chemical && drug.class.psychoactive ? (
                      <div className="badges">
                        {[
                          ...drug.class.chemical,
                          ...drug.class.psychoactive,
                        ].map((item, i) => (
                          <div className="badge" key={i}>
                            {item}
                          </div>
                        ))}
                      </div>
                    ) : drug.class.chemical ? (
                      <div className="badges">
                        {[...drug.class.chemical].map((item, i) => (
                          <div className="badge" key={i}>
                            {item}
                          </div>
                        ))}
                      </div>
                    ) : drug.class.psychoactive ? (
                      <div className="badges">
                        {[...drug.class.psychoactive].map((item, i) => (
                          <div className="badge" key={i}>
                            {item}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <p>{drug?.summary}</p>
              </div>
            </div>
            <HarmPotential
              addictionPotential={drug.addictionPotential}
              toxicity={drug.toxicity ? drug.toxicity : []}
            />
            {drug.roas && (
              <>
                {drug.roas.length > 0 && (
                  <Dosage
                    dose={drug.roas[0].dose ? drug.roas[0].dose : {}}
                    name={drug.roas[0].name}
                  />
                )}
              </>
            )}
            {drug.roas && (
              <>
                {drug.roas.length > 0 && (
                  <Duration
                    duration={
                      drug.roas[0].duration ? drug.roas[0].duration : {}
                    }
                    name={drug.roas[0].name}
                  />
                )}
              </>
            )}
          </div>
          <div className="col-6">
            <Effects effects={drug.effects ? drug.effects : []} />
            <Tolerance tolerance={drug.tolerance ? drug.tolerance : {}} />
            {drug.crossTolerances && (
              <>
                {drug.crossTolerances.includes("psychedelic") && (
                  <SaferPsychedelics />
                )}
              </>
            )}
            {drug.crossTolerances && (
              <>
                {drug.crossTolerances.includes("stimulant") && <SaferUsage />}
              </>
            )}
            <div className="section">
              <div className="bottom">
                <p>
                  The use of psychedelics always has risks. Please use safely
                  and at your own discretion.
                </p>
                <p>
                  Consider talking to a doctor before taking any substance. This
                  app is not intended to encourage the use of drugs, but to help
                  facilitate the safe use of drugs.
                </p>
              </div>
            {props.ad?.id && ( <FlatAdds /> )}

            </div>
          </div>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-view">
        <InnerHeader text="" />
        <div className="header-img">
          <img src={getImage()} alt="" className="drugimg" />
        </div>
        <div className="header-info section">
          {/* <img src={getImage()} alt="" className="img-abs" /> */}
          <div className="above">
            <h1 className="name">{drug.name}</h1>
            <h6>( {drug.name} )</h6>
            {drug.class && (
              <>
                {drug.class.chemical && drug.class.psychoactive ? (
                  <div className="badges">
                    {[...drug.class.chemical, ...drug.class.psychoactive].map(
                      (item, i) => (
                        <div className="badge" key={i}>
                          {item}
                        </div>
                      )
                    )}
                  </div>
                ) : drug.class.chemical ? (
                  <div className="badges">
                    {[...drug.class.chemical].map((item, i) => (
                      <div className="badge" key={i}>
                        {item}
                      </div>
                    ))}
                  </div>
                ) : drug.class.psychoactive ? (
                  <div className="badges">
                    {[...drug.class.psychoactive].map((item, i) => (
                      <div className="badge" key={i}>
                        {item}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
            <p>{drug?.summary}</p>
          </div>
        </div>
        <HarmPotential
          addictionPotential={drug.addictionPotential}
          toxicity={drug.toxicity ? drug.toxicity : []}
        />
        {drug.roas && (
          <>
            {drug.roas.length > 0 && (
              <Dosage
                dose={drug.roas[0].dose ? drug.roas[0].dose : {}}
                name={drug.roas[0].name}
              />
            )}
          </>
        )}
        {drug.roas && (
          <>
            {drug.roas.length > 0 && (
              <Duration
                duration={drug.roas[0].duration ? drug.roas[0].duration : {}}
                name={drug.roas[0].name}
              />
            )}
          </>
        )}
        <Effects effects={drug.effects ? drug.effects : []} />
        <Tolerance tolerance={drug.tolerance ? drug.tolerance : {}} />
        {drug.crossTolerances && (
          <>
            {drug.crossTolerances.includes("psychedelic") && (
              <SaferPsychedelics />
            )}
          </>
        )}
        {drug.crossTolerances && (
          <>{drug.crossTolerances.includes("stimulant") && <SaferUsage />}</>
        )}
        <div className="section">
          <div className="bottom">
            <p>
              The use of psychedelics always has risks. Please use safely and at
              your own discretion.
            </p>
            <p>
              Consider talking to a doctor before taking any substance. This app
              is not intended to encourage the use of drugs, but to help
              facilitate the safe use of drugs.
            </p>
          </div>
        </div>
        {props.ad?.id && ( <FlatAdds /> )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    drug: state.drugs.drug,
    loading: state.drugs.loading,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  fetchDrugDetail,
  fetchAds
})(DrugDetail);
