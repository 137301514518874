import React, { useState, useEffect } from "react";
import "./Home.scss";
import { FiHome, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// component
import Header from "../../components/template/header/Header";
import TopNotification from "../../components/home/topNotification/TopNotification";
import ImageCard from "../../components/home/imageCard/ImageCard";
import Loader from "../../components/loader/Loader";
import FlatAdds from "../../components/adds/flat/FlatAdds";

import { connect } from "react-redux";
import { fetchDrugs } from "../../store/drugs/actions";
import {
  fetchExistingActiveGoal,
  fetchGoalHistory,
} from "../../store/goals/actions";
import {
  setShowNotification,
  fetchDoseNowNotification,
} from "../../store/doses/actions";
import {
  fetchAds
} from "../../store/ads/actions";
import { getToken } from "../../utils/authManager";

import moment from "moment";

const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [personalGrowth, setPersonalGrowth] = useState(0);
  const [drugs, setDrugs] = React.useState([]);

  const value = 0.66;
  const loadData = async () => {
    setLoading(true);
    setDrugs([]);
    try {
      const userId = await getToken();
      if (props.drugs.length === 0) await props.fetchDrugs("Psychedelics");
      await props.fetchExistingActiveGoal({ user_id: userId });
      props.fetchDoseNowNotification(userId);
      props.fetchGoalHistory(userId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const navigateToGoal = () => {
    if (props.existingActiveGoal.length) {
      props.history.push(`/goal/step-3/${props.existingActiveGoal[0].id}`);
    } else {
      props.history.push("/goal/step-1");
    }
  };
  useEffect(() => {
    loadData();
    setInterval(async () => {
      const userId = await getToken();
      props.fetchDoseNowNotification(userId);
    }, 100000);
  }, []);
  // useEffect(() => {
  //   const unsubscribe = props.navigation.addListener('focus', () => {
  //     props.fetchDoseNowNotification(props.user.uid);
  //     props.fetchExistingActiveGoal({user_id: props.user.uid});
  //   });
  //   // Return the function to unsubscribe from the event so it gets removed on unmount
  //   return unsubscribe;
  // }, [props.navigation]);
  useEffect(() => {
    if (props.goalHistory) {
      let growth = 0;
      if (props.goalHistory.length) {
        var startDate = moment(props.goalHistory[0].start_date);
        var endDate = moment(props.goalHistory[0].end_date);
        var totalDays = endDate.diff(startDate, "days");
        if (totalDays > 0) {
          let rating5Value = 100 / totalDays;
          let rating4Value = 100 / totalDays / 2;
          props.goalHistory.map((data) => {
            if (data.mood_rating == 5) {
              growth += rating5Value;
            } else if (data.mood_rating == 4) {
              growth += rating4Value;
            } else if (data.mood_rating == 2) {
              growth -= rating4Value;
            } else if (data.mood_rating == 1) {
              growth -= rating5Value;
            }
          });
          setPersonalGrowth(growth);
        }
      }
    }
  }, [props.goalHistory]);
  useEffect(() => {
    if (props.drugs.length) {
      setDrugs(props.drugs);
    } else {
      setDrugs([]);
    }
  }, [props.drugs]);
 useEffect(() => {
    props.fetchAds();
    const interval = setInterval(() => {
      props.fetchAds();
    }, 80000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      let filteredDrugs = props.drugs.filter((drug) =>
        drug.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDrugs([...filteredDrugs]);
    } else {
      setDrugs([...props.drugs]);
    }
  };
  return (
    <div className="Home">
      {loading && <Loader />}

      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <div className="mobile-wrapper">
          {props.showNotification && <TopNotification />}
          <div className="search-wrapper">
            <FiSearch className="search-icon" />
            <input
              type="text"
              name=""
              id=""
              placeholder="search"
              className="search-input"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="section">
            <h4>Drugs Listing</h4>
            <small>About Drugs</small>
            <div className="druglist mt-2">
              {drugs.map((item, i) => (
                <ImageCard item={item} key={i} />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
            {props.ad?.id && (
              <FlatAdds />
            )}
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="section">
                <h4>Personal Growth</h4>
                <small>
                  Track your personal psychedelics journey with goals!
                </small>
                <Link to="/analitics">
                  <div className="my-2 progress-bar-package">
                    <CircularProgressbar
                      value={personalGrowth}
                      maxValue={100}
                      text={`${parseInt(personalGrowth)}%`}
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-8">
              <div className="list grid ">
                <div onClick={() => navigateToGoal()} className="item-box">
                  <img src="/images/bg/Goal.svg" alt="" />
                  <div className="bottom">Goals</div>
                </div>
                <Link to="/schedule-history" className="item-box">
                  <img src="/images/bg/Scheduled.svg" alt="" />
                  <div className="bottom">Scheduled Doses</div>
                </Link>
                <Link to="/dosenow/step-1" className="item-box">
                  <img src="/images/bg/Dosing.svg" alt="" />
                  <div className="bottom">Dosing Now</div>
                </Link>
                <Link to="/history" className="item-box">
                  <img src="/images/bg/history.svg" alt="" />
                  <div className="bottom">history</div>
                </Link>
                <Link to="/record-past-trip/step-1" className="item-box">
                  <img src="/images/bg/record.svg" alt="" />
                  <div className="bottom">Record Past Dose</div>
                </Link>
                <Link to="/analitics" className="item-box">
                  <img src="/images/bg/mood.svg" alt="" />
                  <div className="bottom">Mood</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* haeder */}
        <Header icon={<FiHome />} text="Home" />
        <div className="mobile-wrapper">
          {props.showNotification && <TopNotification />}
          <div className="search-wrapper">
            <FiSearch className="search-icon" />
            <input
              type="text"
              name=""
              id=""
              placeholder="search"
              className="search-input"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="section">
            <h4>Drugs Listing</h4>
            <small>About Drugs</small>
            <div className="druglist mt-2">
              {drugs.map((item, i) => (
                <ImageCard item={item} key={i} />
              ))}
            </div>
          </div>
          <div className="section">
            <h4>Personal Growth</h4>
            <small>Track your personal psychedelics journey with goals!</small>
            <div className="my-2 progress-bar-package">
            <Link to="/analitics">
              <CircularProgressbar
                value={personalGrowth}
                maxValue={100}
                text={`${parseInt(personalGrowth)}%`}
              />
              </Link>
            </div>
          </div>
          <div className="list grid ">
            <div onClick={() => navigateToGoal()} className="item-box">
              <img src="/images/bg/Goal.svg" alt="" />
              <div className="bottom">Goals</div>
            </div>
            <Link to="/schedule-history" className="item-box">
              <img src="/images/bg/Scheduled.svg" alt="" />
              <div className="bottom">Scheduled Doses</div>
            </Link>
            <Link to="/dosenow/step-1" className="item-box">
              <img src="/images/bg/Dosing.svg" alt="" />
              <div className="bottom">Dosing Now</div>
            </Link>
            <Link to="/history" className="item-box">
              <img src="/images/bg/history.svg" alt="" />
              <div className="bottom">history</div>
            </Link>
            <Link to="/record-past-trip/step-1" className="item-box">
              <img src="/images/bg/record.svg" alt="" />
              <div className="bottom">Record Past Dose</div>
            </Link>
            <Link to="/analitics" className="item-box">
              <img src="/images/bg/mood.svg" alt="" />
              <div className="bottom">Mood</div>
            </Link>
          </div>
          {props.ad?.id && (
            <FlatAdds />
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    drugs: state.drugs.drugs,
    loading: state.drugs.loading,
    existingActiveGoal: state.goals.existingActiveGoal,
    user: state.auth.user,
    showNotification: state.doses.showNotification,
    notification: state.doses.notification,
    goalHistory: state.goals.goalHistory,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  fetchDrugs,
  fetchExistingActiveGoal,
  setShowNotification,
  fetchDoseNowNotification,
  fetchGoalHistory,
  fetchAds
})(Home);
