import React, {useEffect} from "react";
import "./Mood.scss";
import {connect} from 'react-redux';
import {setDose} from '../../../store/doses/actions';

const Mood = (props) => {
  const [active, setActive] = React.useState('');
  const handleSetMood = (item) =>{
    props.setDose({...props.dose, mood_before_dose: item.value})
    setActive(item.name);
  }
  useEffect(() => {
    if(props.dose.mood_before_dose){
      let selectedMood = props.mood.filter(optn => optn.value == props.dose.mood_before_dose)
      if(selectedMood.length){
        setActive(selectedMood[0].name);
      }
    }
  }, [props.dose])
  return (
    <div className="moodWrapper">
      {props.mood.map((item, i) => (
        <div
          className={`mood`}
          key={i}
          onClick={() => {
            handleSetMood(item)
          }}
        >
          <img src={active === item.name ? item.iconActive : item.icon} />
        </div>
      ))}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
  };
}

export default connect(mapStateToProps, {
  setDose,
})(Mood);