import { ADS_LOADING, ADS_SET_DATA, ADS_SET_ADS } from "./constants";

const initialState = {
  loading: false,
  ad: {},
  ads: [],
};

export function adsReducer(state = initialState, action) {
  switch (action.type) {
    case ADS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADS_SET_DATA:
      return {
        ...state,
        ad: action.payload.length > 0 ? action.payload[0] : {},
      };
    case ADS_SET_ADS:
      return {
        ...state,
        ads: action.payload,
      };
    default:
      return state;
  }
}
