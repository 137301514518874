import React from "react";
import "./Tolerance.scss";
import { Link } from "react-router-dom";
import { FiCheck, FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const Tolerance = (props) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Tolerance</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom">
            Full: {props.tolerance.full || "N/A"} <br />
            Half: {props.tolerance.half || "N/A"} <br />
            Zero: {props.tolerance.zero || "N/A"}
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Tolerance;
