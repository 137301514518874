import {
	AUTH_LOGIN,
	AUTH_LOADING,
	AUTH_SUCCESS,
	AUTH_LOGOUT,
  } from './constants';
  
  export const setLogin = (status) => ({
	type: AUTH_LOGIN,
	payload: status,
  });
  export const setLoading = (status) => ({
	type: AUTH_LOADING,
	payload: status,
  });
  export const setAuthSuccess = (data) => ({
	type: AUTH_SUCCESS,
	payload: data,
  });
  export const setAuthLogout = () => async (dispatch) => {
	try {
	//   await GoogleSignin.configure({
	// 	webClientId:
	// 	  '271034602027-dopp96u9ev80fcms7g0sh9faa4h6v8g0.apps.googleusercontent.com',
	//   });
	//   await GoogleSignin.revokeAccess();
	//   await GoogleSignin.signOut();
	} catch (err) {
	  console.log(err);
	} finally {
	  dispatch({
		type: AUTH_LOGOUT,
	  });
	}
  };
  