import React, { useState, useEffect } from "react";
import "./Step1.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import GoalSet from "../../../components/goal/mood/GoalSet";

import { connect } from "react-redux";
import { fetchGoals } from "../../../store/goals/actions";
import { ToastContainer, toast } from "react-toastify";
const GoalStep1 = (props) => {
  const notifyError = (message) => toast.error(message);
  const [loading, setLoading] = useState(false);
  const loadData = async () => {
    setLoading(true);
    await props.fetchGoals();
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.activeGoal) {
      props.history.push("/goal/step-2");
    } else {
      notifyError("Please select a goal to continue");
    }
  };
  return (
    <div className="GoalStep1">
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Goals" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 1: What are your Goals ? Choose your goal
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <GoalSet goal={props.goals} />
              </div>

              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Goals" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 1: What are your Goals ? Choose your goal
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <form action="" className="inner-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <GoalSet goal={props.goals} />
              </div>

              <div className="col-12 d-flex justify-content-center">
                <button className="btn btn-outline-dark" type="submit">
                  Next <FiArrowRight />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/*  */}
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    goals: state.goals.goals,
    loading: state.goals.loading,
    activeGoal: state.goals.activeGoal,
    user: state.auth.user,
  };
}
export default connect(mapStateToProps, {
  fetchGoals,
})(GoalStep1);
