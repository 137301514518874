import React, { useState, useEffect } from "react";
import "./Step2.scss";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import CustomToolBar from "./customToolbar/CustomToolBar";
import { connect } from "react-redux";
import {
  setScheduleDose,
  createScheduleDose,
  fetchScheduleDetails
} from "../../../store/doses/actions";
import { getToken } from "../../../utils/authManager";
import { ToastContainer, toast } from "react-toastify";

const localizer = momentLocalizer(moment);
const ScheduleDoseStep2 = (props) => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const [state, setState] = useState({
    reminderActive: true,
    alarmActive: false,
    events: [],
    target_dates: [],
    btnLoading: false,
  });
  useEffect(() => {
    var dates = [];
    var currDate = moment(props.schedule_dose.start_date).startOf("day");
    var lastDate = moment(props.schedule_dose.end_date).startOf("day");
    let i = 1;
    currDate = currDate.subtract(1, "days");
    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      if (i <= parseInt(props.schedule_dose.days_on)) {
        dates.push(currDate.clone().toDate());
      } else {
        if (
          i ===
          parseInt(props.schedule_dose.days_on) +
            parseInt(props.schedule_dose.days_off)
        ) {
          i = 0;
        } else {
          i++;
          continue;
        }
      }
      i++;
    }
    let eventObj = [];
    dates.map((date) => {
      eventObj.push({
        start: moment(date).toDate(),
        end: moment(date).add(1, "days").toDate(),
        title: props.schedule_dose.substance_type,
      });
    });
    setState({ ...state, events: eventObj, target_dates: dates });
  }, [props.schedule_dose]);
  useEffect(() => {
    if (!props?.schedule_dose?.substance_type) {
      if(props?.match?.params?.id){
        const {id} = props.match.params
        props.fetchScheduleDetails(id)
      }else{
        props.history.push("/schedule-dose/step-1");
      }
    }
  }, [props.schedule_dose]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await setState({ ...state, btnLoading: true });
    try {
      let eventIds = [];
      const userId = await getToken();
      let obj = {
        user_id: userId,
        substance_type: props.schedule_dose.substance_type,
        amount_of_dose: props.schedule_dose.amount_of_dose,
        unit_of_dose: props.schedule_dose.unit_of_dose,
        number_of_dose: props.schedule_dose.number_of_dose,
        start_date: moment(props.schedule_dose.start_date).format(
          "MMM DD, YYYY"
        ),
        end_date: moment(props.schedule_dose.end_date).format("MMM DD, YYYY"),
        days_on: props.schedule_dose.days_on,
        days_off: props.schedule_dose.days_off,
        frequency: props.schedule_dose.frequency,
        target_dates: JSON.stringify(state.target_dates),
        frequency: props.schedule_dose.frequency,
        reminder: props.schedule_dose.reminder ? 1 : 0,
        alarm: props.schedule_dose.alarm ? 1 : 0,
        event_ids: JSON.stringify(eventIds),
        time: moment(props.schedule_dose.time).format("h:mm: a"),
        id: "",
      };
      if (props.schedule_dose.id) {
        obj = {
          ...obj,
          id: props.schedule_dose.id,
        };
      }
      await props.createScheduleDose(obj);
      notifySuccess(
        props?.match?.params?.id
          ? "Dose schedule updated successfully"
          : "Dose schedule successfully added to calendar."
      );
      setTimeout(() => {
        if (props?.match?.params?.id) props.history.push("/");
        else props.history.push("/create-dose");
      }, 3000);
    } catch (err) {
      console.log(err);
      notifyError("Something went wrong!");
    } finally {
      await setState({ ...state, btnLoading: false });
    }
  };
  // console.log(props.schedule_dose)
  return (
    <div className="ScheduleDoseStep2">
      {/* only the mobile version */}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Schedule Dose" desktop={true} />
        <div className="mobile-wrapper mt-5">
          <div>
            <Calendar
              localizer={localizer}
              events={state.events}
              startAccessor="start"
              endAccessor="end"
              defaultView="month"
              components={{
                toolbar: CustomToolBar,
              }}
              style={{ width: "100%", height: "70vh" }}
            />
          </div>
          <div>
            <form action="" className="inner-form" onSubmit={handleSubmit}>
              <div className="row">
              <div className="col-12  d-flex justify-content-between align-items-center">
                <label>Time</label>
                  <div className="my-3">
                    <input
                      type="time"
                      id="time"
                      className="form-control "
                      value={props.schedule_dose.time}
                      onChange={(e) =>
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          time: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <label>Notification</label>
                  <div className="custom-control custom-switch d-flex justify-content-between my-4">
                    <label className="custom-control-label" htmlFor="customSwitch1">
                      {/* Notification */}
                    </label>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      checked={props.schedule_dose.reminder}
                      onChange={(e) =>
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          reminder: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    {state.btnLoading ? (
                      "..."
                    ) : (
                      <>
                        Next <FiArrowRight />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Schedule Dose" />
        <div className="mobile-wrapper">
          <div>
            <Calendar
              localizer={localizer}
              events={state.events}
              startAccessor="start"
              endAccessor="end"
              defaultView="month"
              components={{
                toolbar: CustomToolBar,
              }}
              style={{ width: "93vw", height: "50vh" }}
            />
          </div>
          <div>
            <form action="" className="inner-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12  d-flex justify-content-between align-items-center">
                <label>Time</label>
                  <div className="my-3">
                    <input
                      type="time"
                      id="time"
                      className="form-control "
                      value={props.schedule_dose.time}
                      onChange={(e) =>
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          time: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <label>Notification</label>
                  <div className="custom-control custom-switch d-flex justify-content-between my-4">
                    <label className="custom-control-label" htmlFor="customSwitch1">
                      {/* Notification */}
                    </label>
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch1"
                      onChange={(e) =>{
                        console.log(e.target.checked);
                        props.setScheduleDose({
                          ...props.schedule_dose,
                          reminder: e.target.checked,
                        })
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-center">
                  <button className="btn btn-outline-dark" type="submit">
                    {state.btnLoading ? (
                      "..."
                    ) : (
                      <>
                        Next <FiArrowRight />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* </div> */}
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    schedule_dose: state.doses.schedule_dose,
    user: state.auth.user,
  };
}
export default connect(mapStateToProps, {
  setScheduleDose,
  createScheduleDose,
  fetchScheduleDetails
})(ScheduleDoseStep2);
