import React, { useEffect } from "react";
import "./Step2.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import Emotions from "../../../components/doseNow/emotions/Emotions";

import { connect } from "react-redux";
import { setDose, fetchDoseDetails } from "../../../store/doses/actions";

const DoseNowStep2 = (props) => {
  const emotions = [
    {
      name: "Happy",
      img: "/images/emojies/happy.png",
    },
    {
      name: "Angry",
      img: "/images/emojies/angry.png",
    },
    {
      name: "Sad",
      img: "/images/emojies/sad.png",
    },
    {
      name: "Loved",
      img: "/images/emojies/loved.png",
    },
    {
      name: "Calm",
      img: "/images/emojies/calm.png",
    },
    {
      name: "Anxiety",
      img: "/images/emojies/anxiety.png",
    },
    {
      name: "Confused",
      img: "/images/emojies/confused.png",
    },
    {
      name: "Bored",
      img: "/images/emojies/bored.png",
    },
    {
      name: "Scared",
      img: "/images/emojies/scared.png",
    },
  ];
  useEffect(() => {
    if (!props.dose?.substance_type) {
      if(props?.match?.params?.id){
        const {id} = props.match.params
        props.fetchDoseDetails(id)
      }else{
        props.history.push("/dosenow/step-1");
      }
    }
  }, [props.dose]);
  const handleSubmit = () =>{
    if(props?.match?.params?.id){
      props.history.push(`/dosenow/step-3/${props?.match?.params?.id}`);
    }else{
      props.history.push(`/dosenow/step-3`);
    }
  }
  return (
    <div className="doseNowStep2">
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Dose Now" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 2: Choose three emotions that represent what you are feeling
          </h1>
          <div className="EmotionsWrapper my-3">
            {emotions.map((item, i) => (
              <Emotions item={item} key={i} />
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {/* <Link to="/dosenow/step-3"> */}
                <button className="btn btn-outline-dark" onClick={()=>handleSubmit()}>
                  Next <FiArrowRight />
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose Now" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 2: Choose three emotions that represent what you are feeling
          </h1>
          <div className="EmotionsWrapper my-3">
            {emotions.map((item, i) => (
              <Emotions item={item} key={i} />
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {/* <Link to="/dosenow/step-3"> */}
                <button className="btn btn-outline-dark" onClick={()=>handleSubmit()}>
                  Next <FiArrowRight />
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
  };
}

export default connect(mapStateToProps, {
  setDose,
  fetchDoseDetails
})(DoseNowStep2);
