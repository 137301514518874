import React from "react";
import "./HarmPotential.scss";
import { Link } from "react-router-dom";
import { FiCheck, FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const HarmPotential = (props) => {
  const [open, setOpen] = React.useState(true);
  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Harm Potential</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom">
            <ul className="ul-drug">
              {props.addictionPotential && <li>{props.addictionPotential}</li>}
              {props.toxicity && (
                <>
                  {props.toxicity.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </>
              )}
            </ul>
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default HarmPotential;
