import {
  GOALS_LOADING,
  GOALS_SET_DATA,
  GOALS_SET_ACTIVE_GOAL,
  GOALS_SET_EXISTING_ACTIVE_GOAL,
  GOALS_SET_GOAL_HISTORY
} from './constants';
import {goalService} from './services';
export const setLoading = (status) => ({
  type: GOALS_LOADING,
  payload: status,
});
export const setData = (payload) => ({
  type: GOALS_SET_DATA,
  payload: payload,
});
export const setActiveGoal = (payload) => ({
  type: GOALS_SET_ACTIVE_GOAL,
  payload: payload,
});
export const setExistingActiveGoal = (payload) => ({
  type: GOALS_SET_EXISTING_ACTIVE_GOAL,
  payload: payload
});
export const setGoalHistory = (payload) => ({
  type: GOALS_SET_GOAL_HISTORY,
  payload: payload
});
export const fetchGoals = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    // await dispatch(setLoading(true));
    goalService
      .fetchGoals()
      .then(async (res) => {
        await dispatch(setData(res));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        // dispatch(setLoading(false));
      });
  });
};
export const createGoal = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    goalService
    .createGoal(formData)
    .then(async (res) => {
      dispatch(fetchGoalHistory(formData.user_id))
      dispatch(fetchExistingActiveGoal(formData.user_id))
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
  });
};
export const fetchExistingActiveGoal = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    goalService
    .fetchExistingActiveGoal(params)
    .then(async (res) => {
      dispatch(setExistingActiveGoal(res.active_goal));
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
  });
};
export const dailyUpdateGoal = (formData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    goalService
    .dailyUpdateGoal(formData)
    .then(async (res) => {
      dispatch(fetchGoalHistory(formData.user_id))
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
  });
};
export const fetchGoalHistory = (user_id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    await dispatch(setLoading(true));
    goalService
    .fetchGoalHistory(user_id)
    .then(async (res) => {
      dispatch(setGoalHistory(res.history));
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
  });
};