import React from "react";
import "./Duration.scss";
import { Link } from "react-router-dom";
import { FiInfo, FiChevronDown, FiChevronUp } from "react-icons/fi";

const Duration = (props) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div className="section">
      <div
        className="top"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="left">
          <h6 className="">Duration</h6>
          <FiInfo />
        </div>
        {open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      {open && (
        <>
          <div className="bottom Duration">
            {props.name} <br />
            <div className="infoColors">
              {props.duration.onset && (
                <div className="box onset">
                  <div className="bg"></div>
                  <div className="">Onset</div>
                </div>
              )}
              {(props.duration.duration || props.duration.peak) && (
                <div className="box duration">
                  <div className="bg"></div>
                  <div className="">Duration</div>
                </div>
              )}
              {props.duration.afterglow && (
                <div className="box after-effects">
                  <div className="bg"></div>
                  <div className="">After Effects</div>
                </div>
              )}
            </div>
            {!props.duration.onset && !props.duration.afterglow ? (
              <></>
            ) : (
              <>
                {props.duration.onset && (
                  <div className="time onset">
                    <div className="divider"> </div>
                    <div className="time-frame">
                      <div className="circle">
                        {props.duration.onset.min} <br />{" "}
                        {props.duration.onset.units === "hours" ? "hr" : "min"}
                      </div>
                      <div className="text">Onset started</div>
                    </div>
                    <div className="time-frame">
                      <div className="circle">
                        {props.duration.onset.max} <br />{" "}
                        {props.duration.onset.units === "hours" ? "hr" : "min"}
                      </div>
                      <div className="text">Onset ended</div>
                    </div>
                  </div>
                )}
                {(props.duration.duration || props.duration.peak) && (
                  <div className="time duration">
                    <div className="divider"> </div>
                    {props.duration.duration ? (
                      <>
                        <div className="time-frame">
                          <div className="circle">
                            {props.duration.duration.min} <br />{" "}
                            {props.duration.duration.units === "hours"
                              ? "hr"
                              : "min"}
                          </div>
                          <div className="text">Duration started</div>
                        </div>
                        <div className="time-frame">
                          <div className="circle">
                            {props.duration.duration.max} <br />{" "}
                            {props.duration.duration.units === "hours"
                              ? "hr"
                              : "min"}
                          </div>
                          <div className="text">Duration ended</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="time-frame">
                          <div className="circle">
                            {props.duration.peak.min} <br />{" "}
                            {props.duration.peak.units === "hours"
                              ? "hr"
                              : "min"}
                          </div>
                          <div className="text">Duration started</div>
                        </div>
                        <div className="time-frame">
                          <div className="circle">
                            {props.duration.peak.max} <br />{" "}
                            {props.duration.peak.units === "hours"
                              ? "hr"
                              : "min"}
                          </div>
                          <div className="text">Duration ended</div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {props.duration.afterglow && (
                  <div className="time after-effects">
                    <div className="divider"> </div>

                    <div className="time-frame">
                      <div className="circle">
                        {props.duration.afterglow.min} <br />{" "}
                        {props.duration.afterglow.units === "hours"
                          ? "hr"
                          : "min"}
                      </div>
                      <div className="text">After effects started</div>
                    </div>
                    <div className="time-frame">
                      <div className="circle">
                        {props.duration.afterglow.max} <br />{" "}
                        {props.duration.afterglow.units === "hours"
                          ? "hr"
                          : "min"}
                      </div>
                      <div className="text">After effects ended</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="link">
            <Link to="" className="link-add">
              Source: PsychonautWiki
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Duration;
