import React, { useState, useEffect } from "react";
import "./Step2.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";

import moment from "moment";
import { connect } from "react-redux";
import { createGoal, fetchGoals } from "../../../store/goals/actions";
import { ToastContainer, toast } from "react-toastify";
import { getToken } from "../../../utils/authManager";

const GoalStep2 = (props) => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [state, setState] = useState({
    startDate: moment().format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
    note: "",
    goalTitle: "",
  });
  const loadData = async () => {
    setLoading(true);
    await props.fetchGoals();
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const handleSubmitGoal = async () => {
    try {
      setBtnLoading(true);
      const userId = await getToken();
      await props.createGoal({
        user_id: userId,
        goal_id: props.activeGoal,
        start_date: moment(state.startDate, "yyyy-MM-DD"),
        end_date: moment(state.endDate, "yyyy-MM-DD"),
        note: state.note,
      });
      notifySuccess("Goal created successfully");
      setTimeout(() => {
        props.history.push("/home");
      }, 2000);
    } catch (err) {
      notifyError("Something went wrong");
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    if (props.goals.length) {
      if (props.activeGoal) {
        let selectedGoal = props.goals.filter(
          (goal) => goal.id === props.activeGoal
        );
        if (selectedGoal.length) {
          setState({ ...state, goalTitle: selectedGoal[0].name });
        } else {
          props.history.push("/goal/step-1");
        }
      } else {
        props.history.push("/goal/step-1");
      }
    }
  }, [props.goals, props.activeGoal]);
  return (
    <div className="GoalStep2 pb-5">
      {/* only for desktop */}
      <div className="desktop-view">
        <InnerHeader text="Goal" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 2: Write Something related to your goal
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <div className="inner-form">
            <div className="row">
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Start Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={state.startDate}
                    onChange={(e) =>
                      setState({ ...state, startDate: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> End Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={state.endDate}
                    onChange={(e) =>
                      setState({ ...state, endDate: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="EmotionsCommentWrapper">
                  <div className="EmotionsCommentItem">
                    <div className="Emotions">
                      <div className="radio">
                        <div className="radio-inner" />
                      </div>
                      <h6>{state.goalTitle}</h6>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Add something related ..."
                      className="EmotionsComment"
                      value={state.note}
                      onChangeText={(e) =>
                        setState({ ...state, note: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark"
                onClick={() => handleSubmitGoal()}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  "..."
                ) : (
                  <>
                    Next <FiArrowRight />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Goal" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 2: Write Something related to your goal
          </h1>
          <p className="subText">
            An intention is an aim or goal around which to center your trip.
            Research shows that setting a specific, personal intentions will
            help you get most out of it
          </p>
          <div className="inner-form">
            <div className="row">
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> Start Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={state.startDate}
                    onChange={(e) =>
                      setState({ ...state, startDate: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="input_wrapper pos-relative ">
                  <label htmlFor=""> End Date </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Amount of dose"
                    required
                    value={state.endDate}
                    onChange={(e) =>
                      setState({ ...state, endDate: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="EmotionsCommentWrapper">
                  <div className="EmotionsCommentItem">
                    <div className="Emotions">
                      <div className="radio">
                        <div className="radio-inner" />
                      </div>
                      <h6>{state.goalTitle}</h6>
                    </div>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="Add something related ..."
                      className="EmotionsComment"
                      value={state.note}
                      onChangeText={(e) =>
                        setState({ ...state, note: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark"
                onClick={() => handleSubmitGoal()}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  "..."
                ) : (
                  <>
                    Next <FiArrowRight />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    loading: state.goals.loading,
    activeGoal: state.goals.activeGoal,
    user: state.auth.user,
    goals: state.goals.goals,
  };
}

export default connect(mapStateToProps, {
  createGoal,
  fetchGoals,
})(GoalStep2);
