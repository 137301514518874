import React, { useEffect } from "react";
import "./SideBar.scss";
import { NavLink, useHistory } from "react-router-dom";
import { FiHome, FiPlus, FiThermometer, FiActivity, FiLogOut } from "react-icons/fi";
import { removeToken, getToken } from "../../../utils/authManager";
import firebase from "../../../firebase/config";

import { connect } from "react-redux";
import { fetchProfile } from "../../../store/profile/actions";

const SideBar = (props) => {
  const history = useHistory();
  const handleLogout = () => {
    firebase
    .auth()
    .signOut()
    .then(async () => {
      await removeToken();
      history.push("/");
    });
  };
  const loadData = async () => {
    const userId = await getToken();
    props.fetchProfile(userId);
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className={`sidebar border-right ${props.open} `}>
      <div className="profile">
        <div className="img-wrapper">
          <img src="/images/logo/avatar.png" alt="" />
        </div>
        <div className="h4">{props.profile.name || "Guest"}</div>
        <NavLink to="/profile" className="btn btn-outline-light btn-sm">
          View profile
        </NavLink>
      </div>

      <NavLink className="sidebar_Nav text-decoration-none" to="/home">
        <FiHome className="icon" /> <span> Home </span>
      </NavLink>
      <NavLink className="sidebar_Nav text-decoration-none" to="/create-dose">
        <FiPlus className="icon" /> <span> Create Dose </span>
      </NavLink>
      <NavLink className="sidebar_Nav text-decoration-none" to="/drug-listing">
        <img src="/images/logo/drug-icon.webp" alt="" className="drug-icon one"/>
        <img src="/images/logo/drug-icon-active.webp" alt="" className="drug-icon two"/>
        {/* <FiThermometer className="icon" />  */}<span> Drug Listing</span>
      </NavLink>
      <NavLink className="sidebar_Nav text-decoration-none" to="/analitics">
        <FiActivity className="icon" /> <span> Analytics </span>
      </NavLink>
      <span className="sidebar_Nav text-decoration-none" style={{cursor: "pointer"}} onClick={()=>handleLogout()}>
        <FiLogOut className="icon" /> <span> Logout </span>
      </span>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
  };
}

export default connect(mapStateToProps, {
  fetchProfile,
})(SideBar);