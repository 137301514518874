
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers imported
import { authReducer } from "./auth/reducers";
import { userReducer } from "./users/reducers";
import { profileReducer } from "./profile/reducers";
import { drugReducer } from "./drugs/reducers";
import { doseReducer } from "./doses/reducers";
import { goalReducer } from "./goals/reducers";
import { adsReducer } from "./ads/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  profile: profileReducer,
  drugs: drugReducer,
  doses: doseReducer,
  goals: goalReducer,
  ads: adsReducer
});

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  return store;
}
