import React, { useState, useEffect } from "react";
import CanvasJSReact from "../../../dist/canvasjs/canvasjs.react";
import { connect } from "react-redux";
import moment from "moment";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const MoodEmotionLineChart = (props) => {
  const [data, setData] = useState([]);
  const formatVerticalAxis = (e) => {
    if (e.value === 1) {
      return "Worst";
    } else if (e.value === 2) {
      return "Down";
    } else if (e.value === 3) {
      return "Normal";
    } else if (e.value === 4) {
      return "Better";
    } else if (e.value === 5) {
      return "Best";
    } else if (e.value === -1) {
      return "Not known";
    } else {
      return "";
    }
  };
  const options = {
    animationEnabled: true,
    axisX: {
      valueFormatString: "DD MMM",
    },
    axisY: {
      title: "Emotions",
      labelFormatter: formatVerticalAxis,
      minimum: -1,
      maximum: 5,
      interval: 1,
    },
    data,
  };
  useEffect(() => {
    let list1 = [],
      list2 = [];
    if (props.analytics?.dose_history) {
      props.analytics.dose_history.map((history) => {
        let emotions = JSON.parse(history.emotions);
        if (props.emotion === "Any") {
          list1.push({
            x: moment(history.created_on, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: parseFloat(history.mood_before_dose),
          });
        } else {
          if (emotions.filter((emtn) => emtn.mood === props.emotion).length) {
            list1.push({
              x: moment(history.created_on, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: parseFloat(history.mood_before_dose),
            });
          }
        }
      });
    }
    if (props.analytics?.dose_past_records) {
      props.analytics.dose_past_records.map((history) => {
        let emotions = JSON.parse(history.emotions);
        if (props.emotion === "Any") {
          list2.push({
            x: moment(history.created_on, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: parseFloat(history.mood_after_dose),
          });
        } else {
          if (emotions.filter((emtn) => emtn.mood === props.emotion).length) {
            list2.push({
              x: moment(history.created_on, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: parseFloat(history.mood_after_dose),
            });
          }
        }
      });
    }
    setData([
      {
        yValueFormatString: "#,###",
        xValueFormatString: "DD MMMM",
        type: "spline",
        color: "#FF43CA",
        dataPoints: list1,
      },
      {
        yValueFormatString: "#,###",
        xValueFormatString: "DD MMMM",
        type: "spline",
        color: "#663DB9",
        dataPoints: list2,
      },
    ]);
  }, [props.analytics, props.emotion]);
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    analytics: state.doses.analytics,
  };
}

export default connect(mapStateToProps, {})(MoodEmotionLineChart);
