import React, { useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { FiMenu, FiHome } from "react-icons/fi";
import { removeToken, removeRole } from "../../../utils/authManager";
import { connect } from "react-redux";
import { fetchProfile } from "../../../store/profile/actions";
import "./Header.scss";

function Header(props) {
  var history = useHistory();
  const [open, setOpen] = React.useState(false);

  return (
    <div className="Header fixed-top">
      <div className="desktop-only">
        <div className="d-flex justify-content-between align-items-center">
          {/* <Link className="left d-flex justify-content-start align-items-center text-decoration-none" to="/">
            <FiMenu className="menu" onClick={props.toggleSidebar} />
            <img src="/images/logo/logo.png" className="logo" alt="" />
            <h6 className='m-0 font-weight-bold'>Tune In Psychedelics</h6>
          </Link> */}
          <Link to="/" className="my-0 mr-md-auto text-decoration-none">
            <h6 className="my-0 mr-md-auto font-weight-bold text-white color-violet text-header-sm">
              <img src="/images/logo/logo.png" alt="" className="logo" />
              <span className="d-none d-sm-inline">Tune In Psychedelics</span>
            </h6>
          </Link>
          <div className="middle d-none">
            {props.icon}
            <span className="h6 ml-2 font-weight-bold font-color ">
              {props.text}
            </span>
          </div>
          <div className="right"></div>
        </div>
      </div>

      <div className="mobile-only d-flex justify-content-start align-items-center ">
        {props.icon}
        <span className="h6 ml-2 font-weight-bold font-color ">
          {props.text}
        </span>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  loading: state.profile.loading,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, {
  fetchProfile,
})(Header);
