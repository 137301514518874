import React, { useEffect } from "react";
import "./Profile.scss";
import {
  FiUser,
  FiEdit2,
  FiPhone,
  FiCalendar,
  FiLink,
  FiLock,
  FiLogOut,
  FiShare2,
} from "react-icons/fi";
import { Link } from "react-router-dom";

// component
import Header from "../../components/template/header/Header";
import Loader from "../../components/loader/Loader";
import FlatAdds from "../../components/adds/flat/FlatAdds";

import firebase from "../../firebase/config";
import { connect } from "react-redux";
import { fetchProfile } from "../../store/profile/actions";
import { fetchExistingActiveGoal } from "../../store/goals/actions";
import { removeToken, getToken } from "../../utils/authManager";

import { CURRENT_URL } from "../../config/index";

const Profile = (props) => {
  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(async () => {
        await removeToken();
        props.history.push("/");
      });
  };
  const loadData = async () => {
    const userId = await getToken();
    props.fetchProfile(userId);
    props.fetchExistingActiveGoal({ user_id: userId });
  };
  const navigateToGoal = () => {
    if (props.existingActiveGoal.length) {
      props.history.push(`/goal/step-3/${props.existingActiveGoal[0].id}`);
    } else {
      props.history.push("/goal/step-1");
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="profile">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        <div className="row">
          <div className="col-6">
            <div className="devider">
              <div className="name">User Detail</div>
              <Link to="/edit-profile" className="btn btn-light">
                <FiEdit2 /> Edit
              </Link>
            </div>
            <div className="list">
              <div className="item">
                <div className="left">
                  <div className="h4">
                    {props.profile.name ? props.profile.name.substr(0, 1) : "g"}
                  </div>
                </div>
                <div className="right">{props.profile.email}</div>
              </div>
              <div className="item">
                <div className="left">
                  <FiUser />
                </div>
                <div className="right">{props.profile.name || "Guest"}</div>
              </div>
              <div className="item">
                <div className="left">
                  <FiPhone />
                </div>
                <div className="right">
                  {props.profile.phone || "No number available"}
                </div>
              </div>
              <div className="item">
                <div className="left">
                  <FiCalendar />
                </div>
                <div className="right">
                  {props.profile.dob || "No DOB available"}
                </div>
              </div>
              <div className="item ">
                <div className="left">
                  <FiLink />
                </div>
                <div className="right">Bio</div>
              </div>
              <p className="bio">{props.profile.bio || "No bio available"}</p>
            </div>
          </div>
          <div className="col-6">
            <div className="devider">
              <div className="name">Share</div>
            </div>
            <div className="list">
              <a
                href={`https://www.addtoany.com/share/?linkurl=${CURRENT_URL}`}
                className="item"
                target="_blank"
              >
                <div className="left">
                  <FiShare2 />
                </div>
                <div className="right">Share with friends</div>
              </a>
            </div>
            <div className="devider">
              <div className="name">Settings</div>
            </div>
            <div className="list">
              <Link to="/change-password" className="item">
                <div className="left">
                  <FiLock />
                </div>
                <div className="right">Change password</div>
              </Link>
              <span className="item" onClick={() => handleLogout()}>
                <div className="left">
                  <FiLogOut />
                </div>
                <div className="right">Log out</div>
              </span>
            </div>
            {props.ad?.id && (
            <FlatAdds />
            )}
          </div>
        </div>

        <div className="devider">
          <div className="name">Others</div>
        </div>
        <div className="list grid ">
          <div className="item-box" onClick={() => navigateToGoal()}>
            <img src="/images/bg/Goal.svg" alt="" />
            <div className="bottom">Goals</div>
          </div>
          <Link to="schedule-history" className="item-box">
            <img src="/images/bg/Scheduled.svg" alt="" />
            <div className="bottom">Scheduled Doses</div>
          </Link>
          <Link to="/dosenow/step-1" className="item-box">
            <img src="/images/bg/Dosing.svg" alt="" />
            <div className="bottom">Dosing Now</div>
          </Link>
          <Link to="/history" className="item-box">
            <img src="/images/bg/history.svg" alt="" />
            <div className="bottom">history</div>
          </Link>
          <Link to="/record-past-trip/step-1" className="item-box">
            <img src="/images/bg/record.svg" alt="" />
            <div className="bottom">Record Past Dose</div>
          </Link>
          <Link to="/analitics" className="item-box">
            <img src="/images/bg/mood.svg" alt="" />
            <div className="bottom">Mood</div>
          </Link>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        <Header icon={<FiUser />} text="Profile" />

        <div className="devider">
          <div className="name">User Detail</div>
          <Link to="/edit-profile" className="btn btn-light">
            <FiEdit2 /> Edit
          </Link>
        </div>
        <div className="list">
          <div className="item">
            <div className="left">
              <div className="h4">
                {props.profile.name ? props.profile.name.substr(0, 1) : "g"}
              </div>
            </div>
            <div className="right">{props.profile.email}</div>
          </div>
          <div className="item">
            <div className="left">
              <FiUser />
            </div>
            <div className="right">{props.profile.name || "Guest"}</div>
          </div>
          <div className="item">
            <div className="left">
              <FiPhone />
            </div>
            <div className="right">
              {props.profile.phone || "No number available"}
            </div>
          </div>
          <div className="item">
            <div className="left">
              <FiCalendar />
            </div>
            <div className="right">
              {props.profile.dob || "No DOB available"}
            </div>
          </div>
          <div className="item ">
            <div className="left">
              <FiLink />
            </div>
            <div className="right">Bio</div>
          </div>
          <p className="bio">{props.profile.bio || "No bio available"}</p>
        </div>

        <div className="devider">
          <div className="name">Share</div>
        </div>
        <div className="list">
          <a
            href={`https://www.addtoany.com/share/?linkurl=${CURRENT_URL}`}
            className="item"
            target="_blank"
          >
            <div className="left">
              <FiShare2 />
            </div>
            <div className="right">Share with friends</div>
          </a>
        </div>
        <div className="devider">
          <div className="name">Settings</div>
        </div>
        <div className="list">
          <Link to="/" className="item">
            <div className="left">
              <FiLock />
            </div>
            <div className="right">Change password</div>
          </Link>
          <span className="item" onClick={() => handleLogout()}>
            <div className="left">
              <FiLogOut />
            </div>
            <div className="right">Log out</div>
          </span>
        </div>
        <div className="devider">
          <div className="name">Others</div>
        </div>
        <div className="list grid ">
          <div className="item-box" onClick={() => navigateToGoal()}>
            <img src="/images/bg/Goal.svg" alt="" />
            <div className="bottom">Goals</div>
          </div>
          <Link to="schedule-history" className="item-box">
            <img src="/images/bg/Scheduled.svg" alt="" />
            <div className="bottom">Scheduled Doses</div>
          </Link>
          <Link to="/dosenow/step-1" className="item-box">
            <img src="/images/bg/Dosing.svg" alt="" />
            <div className="bottom">Dosing Now</div>
          </Link>
          <Link to="/history" className="item-box">
            <img src="/images/bg/history.svg" alt="" />
            <div className="bottom">history</div>
          </Link>
          <Link to="/record-past-trip/step-1" className="item-box">
            <img src="/images/bg/record.svg" alt="" />
            <div className="bottom">Record Past Dose</div>
          </Link>
          <Link to="/analitics" className="item-box">
            <img src="/images/bg/mood.svg" alt="" />
            <div className="bottom">Mood</div>
          </Link>
        </div>
        {props.ad?.id && (
        <FlatAdds />
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isLogin: state.auth.isLogin,
    profile: state.profile.profile,
    loading: state.profile.loading,
    existingActiveGoal: state.goals.existingActiveGoal,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  fetchProfile,
  fetchExistingActiveGoal,
})(Profile);
