import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Routes />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
