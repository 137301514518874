import React, { useEffect } from "react";
import "./Step4.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";
import Mood from "../../../components/doseNow/mood/Mood";
import AdModal from "../../../components/adds/adModal/AdModal";

import { connect } from "react-redux";
import {
  setDose,
  createDose,
  updateDose,
  resetDose,
  fetchDoseDetails,
} from "../../../store/doses/actions";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { getToken } from "../../../utils/authManager";

const DoseNowStep4 = (props) => {
  const mood = [
    {
      name: "1. Feeling Worst",
      icon: "/images/emotions/sad.png",
      iconActive: "/images/emotions/sadColor.png",
      value: 1,
    },
    {
      name: "2. Feeling Down",
      icon: "/images/emotions/down.png",
      iconActive: "/images/emotions/downColor.png",
      value: 2,
    },
    {
      name: "3. Feeling Normal",
      icon: "/images/emotions/bleh.png",
      iconActive: "/images/emotions/blehColor.png",
      value: 3,
    },
    {
      name: "4. Feeling Better",
      icon: "/images/emotions/smle.png",
      iconActive: "/images/emotions/smleColor.png",
      value: 4,
    },
    {
      name: "5. Feeling Best",
      icon: "/images/emotions/happy.png",
      iconActive: "/images/emotions/happyColor.png",
      value: 5,
    },
  ];
  const [loading, setLoading] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleCreateDose = async () => {
    setLoading(true);
    try {
      const userId = await getToken();
      let formData = {
        ...props.dose,
        user_id: userId,
        dose_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        dose_time: moment().format("HH:mm"),
        emotions: JSON.stringify(props.dose.emotions),
        amount_of_dose: `${props.dose.amount_of_dose} ${props.dose.unit_of_dose}`,
      };
      const resp = await props.createDose(formData);
      if (resp.status) {
        if(props.ad?.id){
          setAddModal(true);
          notifySuccess("Dose created successfully");
        }else{
          notifySuccess("Dose created successfully");
          setTimeout(() => {
            props.history.push("/create-dose");
            props.resetDose();
          }, 3000);
        }
        // setShowAdModal(true);
      } else {
        notifyError("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
      notifyError("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateDose = async () => {
    setLoading(true);
    try {
      const userId = await getToken();
      let formData = {
        ...props.dose,
        user_id: userId,
        dose_date: moment(props.dose.dose_date).format("YYYY-MM-DD HH:mm:ss"),
        dose_time: moment(props.dose.dose_time).format("HH:mm"),
        emotions: JSON.stringify(props.dose.emotions),
        amount_of_dose: `${props.dose.amount_of_dose} ${props.dose.unit_of_dose}`,
        id: props?.match?.params?.id,
      };
      const resp = await props.updateDose(formData);
      if (resp.status) {
        notifySuccess("Dose updated successfully");
        setTimeout(() => {
          props.history.push("/history");
          props.resetDose();
        }, 3000);
      } else {
        notifyError("Something went wrong!");
      }
    } catch (err) {
      console.log(err.response.data);
      notifyError("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!props.dose?.substance_type) {
      if (props?.match?.params?.id) {
        const { id } = props.match.params;
        props.fetchDoseDetails(id);
      } else {
        props.history.push("/dosenow/step-1");
      }
    }
  }, [props.dose]);
  return (
    <div className="doseNowStep4">
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Dose Now" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 4: Mood before dose</h1>
          <Mood mood={mood} />

          <div className="EmotionsCommentWrapper">
            <div className="EmotionsCommentItem">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Add something related ..."
                className="EmotionsComment"
                value={props.dose.comments}
                onChange={(e) =>
                  props.setDose({ ...props.dose, comments: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark"
                onClick={() =>
                  props?.match?.params?.id
                    ? handleUpdateDose()
                    : handleCreateDose()
                }
              >
                {loading ? (
                  "..."
                ) : (
                  <>
                    Next <FiArrowRight />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <AdModal
        visible={addModal}
        close={() => {
          setAddModal(!addModal);
          props.history.push("/create-dose");
          props.resetDose();
        }}
      />
      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose Now" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div className="bg-primary" />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">Step 4: Mood before dose</h1>
          <Mood mood={mood} />

          <div className="EmotionsCommentWrapper">
            <div className="EmotionsCommentItem">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Add something related ..."
                className="EmotionsComment"
                value={props.dose.comments}
                onChange={(e) =>
                  props.setDose({ ...props.dose, comments: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-dark"
                onClick={() =>
                  props?.match?.params?.id
                    ? handleUpdateDose()
                    : handleCreateDose()
                }
              >
                {loading ? (
                  "..."
                ) : (
                  <>
                    Next <FiArrowRight />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
    ad: state.ads.ad
  };
}

export default connect(mapStateToProps, {
  setDose,
  createDose,
  updateDose,
  resetDose,
  fetchDoseDetails,
})(DoseNowStep4);
