import {
    DRUGS_LOADING,
    DRUGS_SET_DATA,
    DRUGS_SET_DETAIL
  } from './constants';
import {drugService} from "./services";
import shuffle from "../../utils/Shuffle";

  export const setLoading = status => ({
    type: DRUGS_LOADING,
    payload: status
  });
  export const setData = payload => ({
    type: DRUGS_SET_DATA,
    payload: payload
  });
  export const setDetail = payload => ({
    type: DRUGS_SET_DETAIL,
    payload: payload
  });
  export const fetchDrugs = (psychoactiveClass) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(setLoading(true));
      drugService
        .fetchDrugs(psychoactiveClass)
        .then(async (res) => {
          const mdma = await dispatch(fetchDrugDetail("Mdma"));
          const Ketamine = await dispatch(fetchDrugDetail("Ketamine"));
          const cannabis = await dispatch(fetchDrugDetail("Cannabis"));
          await dispatch(setData(shuffle([...res.data.substances, ...mdma.data.substances, ...Ketamine.data.substances, ...cannabis.data.substances])));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    });
  };
  export const fetchDrugDetail = (name) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(setLoading(true));
      drugService
      .fetchDrugDetail(name)
      .then(async (res) => {
        await dispatch(setDetail(res.data.substances[0]));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
    });
  };