import React from "react";
import "./CreateDose.scss";
import { Link } from "react-router-dom";

// component
import Header from "../../components/template/header/Header";

const CreateDose = (props) => {
  return (
    <div className="createDose">
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* bg waves */}
        {/* <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" /> */}
        <div className="desktop-wrapper">
          <div className="header">Get your dose</div>
          <p style={{textAlign: "justify"}}>
           Tune In Psychedelics offers easy to use psychedelic dosage tracking so psychonauts and clinical researchers can better understand how psychedelics aid in the mental health crisis
          </p>
          <p className="mb-4" style={{textAlign: "justify"}}>
           Our team has developed this app so users can track every aspect of their psychedelic experience. We are bringing harm-reduction and safe use practices to every user's fingertips. We hope to create a safer and more widely accepted society around phychedelics.
          </p>
          <Link to="/schedule-dose/step-1" className="btn btn-primary">
            Create Dose Schedule
          </Link>
          <Link to="/dosenow/step-1" className="btn btn-primary">
            Dose Now
          </Link>
          <Link to="/record-past-trip/step-1" className="btn btn-primary">
            Record Past Trip
          </Link>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        <div className="mobile-wrapper">
          <div className="header">Get your dose</div>
          <p style={{textAlign: "justify"}}>
            Tune In Psychedelics offers easy to use psychedelic dosage tracking so psychonauts and clinical researchers can better understand how psychedelics aid in the mental health crisis
          </p>
          <p className="mb-4" style={{textAlign: "justify"}}>
            Our team has developed this app so users can track every aspect of their psychedelic experience. We are bringing harm-reduction and safe use practices to every user's fingertips. We hope to create a safer and more widely accepted society around phychedelics.
          </p>
          <Link to="/schedule-dose/step-1" className="btn btn-primary">
            Create Dose Schedule
          </Link>
          <Link to="/dosenow/step-1" className="btn btn-primary">
            Dose Now
          </Link>
          <Link to="/record-past-trip/step-1" className="btn btn-primary">
            Record Past Trip
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CreateDose;
