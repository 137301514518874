import {
  DOSES_LOADING,
  DOSES_SET_DATA,
  DOSES_SET_DETAIL,
  DOSES_SET_DOSE,
  DOSES_RESET_DOSE,
  DOSES_SET_SCHEDULE_DOSE,
  DOSES_RESET_SCHEDULE_DOSE,
  DOSES_SET_SCHEDULES,
  DOSES_SET_NOTIFICATION,
  DOSES_SET_SHOW_NOTIFICATION,
  DOSES_SET_ANALYTICAL_DATA,
} from "./constants";

const initialState = {
  loading: false,
  doses: [],
  schedules: [],
  dose: {
    dose_type: "macro",
    substance_type: "",
    duration: {},
    amount_of_dose: "",
    unit_of_dose: "g",
    emotions: [],
    mood_before_dose: -1,
    comments: "",
    dose_date: Date.now(),
    dose_time: Date.now(),
  },
  schedule_dose: {
    substance_type: "",
    amount_of_dose: "",
    unit_of_dose: "",
    number_of_dose: "",
    start_date: new Date(),
    end_date: new Date(),
    time: new Date(),
    days_on: "",
    days_off: "",
    frequency: "",
    alarm: false,
    reminder: true,
  },
  showNotification: false,
  notification: {},
  analytics: {},
};

export function doseReducer(state = initialState, action) {
  switch (action.type) {
    case DOSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DOSES_SET_DATA:
      return {
        ...state,
        doses: action.payload,
      };
    case DOSES_SET_DETAIL:
      return {
        ...state,
        dose: action.payload,
      };
    case DOSES_SET_DOSE:
      return {
        ...state,
        dose: { ...action.payload },
      };
    case DOSES_RESET_DOSE:
      return {
        ...state,
        dose: {
          dose_type: "macro",
          substance_type: "",
          amount_of_dose: "",
          unit_of_dose: "g",
          emotions: [],
          mood_before_dose: -1,
          comments: "",
          dose_date: Date.now(),
          dose_time: Date.now(),
        },
      };
    case DOSES_SET_SCHEDULE_DOSE:
      return {
        ...state,
        schedule_dose: { ...action.payload },
      };
    case DOSES_RESET_SCHEDULE_DOSE:
      return {
        ...state,
        schedule_dose: {
          substance_type: "",
          amount_of_dose: "",
          unit_of_dose: "",
          number_of_dose: "",
          start_date: new Date(),
          end_date: new Date(),
          time: new Date(),
          days_on: "",
          days_off: "",
          frequency: "",
          alarm: false,
          reminder: true,
        },
      };
    case DOSES_SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      };
    case DOSES_SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case DOSES_SET_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action.payload,
      };
    case DOSES_SET_ANALYTICAL_DATA:
      return {
        ...state,
        analytics: action.payload,
      };
    default:
      return state;
  }
}
