import React, { useEffect } from "react";
import "./Step2.scss";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

// component
import InnerHeader from "../../../components/template/innerHeader/InnerHeader";

import { connect } from "react-redux";
import { setDose } from "../../../store/doses/actions";

const RecordDoseAfter2 = (props) => {
  const Options = [
    {
      name: "Happy",
      image: "/images/emojies/happy.png",
    },
    {
      name: "Angry",
      image: "/images/emojies/angry.png",
    },
    {
      name: "Sad",
      image: "/images/emojies/sad.png",
    },
    {
      name: "Loved",
      image: "/images/emojies/loved.png",
    },
    {
      name: "Calm",
      image: "/images/emojies/calm.png",
    },
    {
      name: "Anxiety",
      image: "/images/emojies/anxiety.png",
    },
    {
      name: "Confused",
      image: "/images/emojies/confused.png",
    },
    {
      name: "Bored",
      image: "/images/emojies/bored.png",
    },
    {
      name: "Scared",
      image: "/images/emojies/scared.png",
    },
  ];
  const handleAddComment = (comment, index) => {
    let moods = props.dose.emotions;
    moods[index]["comment"] = comment;
    props.setDose({ ...props.dose, emotions: [...moods] });
  };
  const handleSubmit = () => {
    if(props?.match?.params?.doseid){
      props.history.push(`/mid-trip/step-3/${props?.match?.params?.doseid}/${props?.match?.params?.duration}`);
    }else{
      props.history.push(`/`);
    }
  };
  const getImage = (item) =>{
    let arr = Options.filter((op) => op.name === item.mood);
    if(arr?.length){
      return arr[0].image
    }
  }
  return (
    <div className="recordDoseAfter2">
      {/* only the desktop version */}
      <div className="desktop-view">
        {/* haeder */}
        <InnerHeader text="Dose Now" desktop={true} />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 3: Write somethings about those emotions
          </h1>
          <div className="EmotionsCommentWrapper my-3">
            {props.dose.emotions.map((item, i) => (
              <div className="EmotionsCommentItem" key={i}>
                <div className="Emotions">
                  <img
                    src={getImage(item)}
                    alt=""
                  />
                  <h6>{item.mood}</h6>
                </div>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Add something related ..."
                  className="EmotionsComment"
                  value={item.comment}
                  onChange={(e) => handleAddComment(e.target.value, i)}
                ></textarea>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {/* <Link to="/dosenow/step-4"> */}
                <button className="btn btn-outline-dark" onClick={()=>handleSubmit()}>
                  Next <FiArrowRight />
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose Now" />
        {/* Devided Steps */}
        <div className="devided-steps">
          <div className="bg-primary" />
          <div className="bg-primary" />
          <div />
        </div>
        {/*  */}
        <div className="mobile-wrapper">
          <h1 className="header">
            Step 3: Write somethings about those emotions
          </h1>
          <div className="EmotionsCommentWrapper my-3">
            {props.dose.emotions.map((item, i) => (
              <div className="EmotionsCommentItem" key={i}>
                <div className="Emotions">
                  <img
                    src={getImage(item)}
                    alt=""
                  />
                  <h6>{item.mood}</h6>
                </div>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Add something related ..."
                  className="EmotionsComment"
                  value={item.comment}
                  onChange={(e) => handleAddComment(e.target.value, i)}
                ></textarea>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {/* <Link to="/dosenow/step-4"> */}
                <button className="btn btn-outline-dark" onClick={()=>handleSubmit()}>
                  Next <FiArrowRight />
                </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    dose: state.doses.dose,
  };
}

export default connect(mapStateToProps, {
  setDose,
})(RecordDoseAfter2);
