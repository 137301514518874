export const DOSES_LOADING = 'DOSES_LOADING';
export const DOSES_SET_DATA = 'DOSES_SET_DATA';
export const DOSES_SET_DETAIL = 'DOSES_SET_DETAIL';
export const DOSES_SET_DOSE = 'DOSES_SET_DOSE';
export const DOSES_RESET_DOSE = 'DOSES_RESET_DOSE';
export const DOSES_SET_SCHEDULE_DOSE = 'DOSES_SET_SCHEDULE_DOSE';
export const DOSES_RESET_SCHEDULE_DOSE = 'DOSES_RESET_SCHEDULE_DOSE';
export const DOSES_SET_SCHEDULES = 'DOSES_SET_SCHEDULES';
export const DOSES_SET_NOTIFICATION = 'DOSES_SET_NOTIFICATION';
export const DOSES_SET_SHOW_NOTIFICATION = 'DOSES_SET_SHOW_NOTIFICATION';
export const DOSES_SET_ANALYTICAL_DATA = 'DOSES_SET_ANALYTICAL_DATA';