import axios from 'axios';
import {API_URL} from '../../config';

function fetchAds() {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}AdsMedia/fetchRandomAd`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function incrementClick(id) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}AdsMedia/incrementClicks?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function incrementImpressions(formData) {
  return new Promise(async (resolve, reject) => {
    axios
    .post(`${API_URL}AdsMedia/incrementImpressions`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
export const adsService = {
  fetchAds,
  incrementClick,
  incrementImpressions
};
