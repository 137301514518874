import {
  AUTH_LOGIN,
  AUTH_LOADING,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "./constants";
import { getToken } from "../../utils/authManager";

const initialState = {
  loading: false,
  isLogin: false,
  skipIntro: false,
  // token: null,
  user: {},
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        isLogin: action.payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        // token: action.payload.token,
        isLogin: true,
        user: action.payload,
        skipIntro: true,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        isLogin: false,
        user: {},
      };
    default:
      return state;
  }
}
