import axios from "axios";
import { API_URL } from "../../config/";

function createDose(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}dose/create`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function fetchDoses(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}dose?user_id=${uid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createScheduleDose(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}dose/create_schedule`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function fetchSchedules(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}dose/fetch_schedules?user_id=${uid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function changeStatus(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}dose/changeStatus`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        reject(err);
      });
  });
}
function fetchCurrentDose(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}dose/fetch_current?user_id=${uid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteDose(id) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        `${API_URL}dose/delete`,
        { id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        reject(err);
      });
  });
}
function updateDose(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}dose/update`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createPastRecord(formData) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${API_URL}dose/create_past_record`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function fetchAnalyticalData(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}dose/fetch_analytics?user_id=${uid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteScheduleDose(id) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        `${API_URL}dose/delete_schedule`,
        { id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        reject(err);
      });
  });
}
function fetchDoseDetails(id) {
  return new Promise(async (resolve, reject) => {
    axios
    .get(`${API_URL}dose/details?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
function fetchScheduleDetails(id) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}dose/schedule_detail?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const doseService = {
  createDose,
  fetchDoses,
  createScheduleDose,
  fetchSchedules,
  changeStatus,
  fetchCurrentDose,
  deleteDose,
  updateDose,
  createPastRecord,
  fetchAnalyticalData,
  deleteScheduleDose,
  fetchDoseDetails,
  fetchScheduleDetails
};
