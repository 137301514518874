import React from "react";
import "./MainView.scss";

// component
import Bottom from "../../components/template/bottom/Bottom";
import SideBar from "../../components/template/sideBar/SideBar";
import Header from "../../components/template/header/Header";

const MainView = (props) => {
  const { children } = props;

  const [sidebar, setSidebar] = React.useState(true);

  return (
    <main className="mainView">
      <div className="main">
        <Header
          toggleSidebar={() => {
            setSidebar(!sidebar);
          }}
        />
        <SideBar open={sidebar} />
        <div className=" main-wrapper">
          <div className="container">{children}</div>
        </div>
      </div>

      <div className="mobile-only">
        {children}

        <Bottom />
      </div>
    </main>
  );
};

export default MainView;
