import axios from 'axios';
import {API_URL} from '../../config/';

function fetchGoals(uid) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}goal`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createGoal(formData) {
  return new Promise(async (resolve, reject) => {
    axios
    .post(`${API_URL}goal/create`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
function fetchExistingActiveGoal(params) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}goal/active?user_id=${params.user_id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function dailyUpdateGoal(formData) {
  return new Promise(async (resolve, reject) => {
    axios
    .post(`${API_URL}goal/daily_update`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
  });
}
function fetchGoalHistory(user_id) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${API_URL}goal/daily_history?user_id=${user_id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const goalService = {
  fetchGoals,
  createGoal,
  fetchExistingActiveGoal,
  dailyUpdateGoal,
  fetchGoalHistory
};
