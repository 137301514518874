import validator from 'validator';
import isEmpty from '../isEmpty';
const validateSignup = data => {
  let errors = {};

  data.email = !isEmpty(data.email) ? data.email.trim() : "";
  data.password = !isEmpty(data.password) ? data.password : "";
  data.confirmPassword = !isEmpty(data.confirmPassword) ? data.confirmPassword : "";

  if (!validator.isEmail(data.email)) {
    errors.email = "Invalid email";
  }
  if (validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  }
  if (validator.isEmpty(data.password)) {
    errors.password = "Password is required";
  }
  if (!validator.equals(data.password, data.confirmPassword)) {
    errors.confirmPassword = "Confirm password is not matching with password";
  }
  if (validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = "Confirm password is required";
  }
 
  return {
    errors,
    isValid: isEmpty(errors)
  };
};

export default validateSignup;
