import React, { useState, useEffect } from "react";
import "./ScheduleHistory.scss";
import { FiFilter } from "react-icons/fi";

// component
import InnerHeader from "../../components/template/innerHeader/InnerHeader";
import ScheduleHistoryItem from "../../components/scheduleHistory/ScheduleHistoryItem";
import Loader from "../../components/loader/Loader";

import { connect } from "react-redux";
import { fetchSchedules } from "../../store/doses/actions";
import { getToken } from "../../utils/authManager";

const ScheduleHistory = (props) => {
  const loadData = async () => {
    try {
      const userId = await getToken();
      props.fetchSchedules(userId);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="ScheduleHistory">
      {props.loading && <Loader />}
      {/* only the desktop version */}
      <div className="desktop-view">
        <InnerHeader text="Schedule History" desktop={true} />
        <div className="mobile-wrapper">
          <div
            className="d-flex justify-content-center align-items-center flex-column  my-3"
            // style={{ zIndex: 9999 }}
          >
            {props.schedules.map((item, i) => (
              <ScheduleHistoryItem key={i} item={item} />
            ))}
          </div>
        </div>
      </div>

      {/* only the mobile version */}
      <div className="mobile-only">
        {/* bg waves */}
        <img src="/images/bg/TOP_GREY_LAYERS.svg" alt="" className="top_wave" />
        {/* haeder */}
        <InnerHeader text="Dose History" />
        <div className="mobile-wrapper">
          <div
            className="d-flex justify-content-center align-items-center flex-column my-3"
            // style={{ zIndex: 9999 }}
          >
            {props.schedules.map((item, i) => (
              <ScheduleHistoryItem key={i} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    schedules: state.doses.schedules,
    loading: state.doses.loading,
  };
}

export default connect(mapStateToProps, {
  fetchSchedules,
})(ScheduleHistory);
