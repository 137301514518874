import {
  DRUGS_LOADING,
  DRUGS_SET_DATA,
  DRUGS_SET_DETAIL,
  } from "./constants";

  const initialState= {
    loading: false,
    drugs: [],
    drug: {}
  };
  
  export function drugReducer(
    state = initialState,
    action
  ){
    switch (action.type) {
      case DRUGS_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case DRUGS_SET_DATA:
        return {
          ...state,
          drugs: action.payload,
        };
      case DRUGS_SET_DETAIL:
        return {
          ...state,
          drug: action.payload,
        };
      default:
        return state;
    }
  }
  